import React, { useEffect, useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';

const stripePromise = loadStripe('pk_test_51OxpbCGj3q9OEgX1LRc2KdSA4mwwAI1KejyGjA0xtiWFhVn0qtIkOP5YQ8TY1Zf5ydt8gbkQcUY9pc1K3d2MpIDV00pJtNaQz3');

const Message = ({ message }) => (
  <section>
    <p>{message}</p>
  </section>
);

function CheckoutForm() {
  
  const [message, setMessage] = useState('');

  useEffect(() => {
    // Check to see if this is a redirect back from Checkout
    const query = new URLSearchParams(window.location.search);

    if (query.get('success')) {
      setMessage('Order placed! You will receive an email confirmation.');
    }

    if (query.get('canceled')) {
      setMessage("Order canceled -- continue to shop around and checkout when you're ready.");
    }
  }, []);

  return message ? <Message message={message} /> : (
    <div className="flex flex-col font-roboto items-center justify-start mx-auto w-full">
      <div  className="backdrop-opacity-[0.5] bg-gray-900  flex flex-col items-center justify-end   w-full">
      <div   className="flex md:flex-col flex-row  items-start justify-between mx-auto md:px-5 w-full"></div>
    <section>
      <div className="product">
        <img src="https://www.platoforms.com/images/posts/2021/stripe-payment-form-creation.jpg" alt="The cover of Stubborn Attachments" className='w-[550px]' />
       
     
      <form action="https://event-backend.isdemo.in/api/v1/create-payment-intent" method="POST" className='text-center text-white'>
        <input type="hidden" name="amount" value={30} />
        <input type="hidden" name="product_name" value="event" />
        <button type="submit" class="text-white bg-gradient-to-r from-purple-500 via-purple-600 to-purple-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-purple-300 dark:focus:ring-purple-800  rounded-lg text-[20px] px-[100px] py-[15px] text-center me-2 mb-2 text-[white] mt-[30px] ">Pay Rs.30/-</button>
      </form>
      </div>
    </section>
    </div>
    </div>
    
  );
}

export default CheckoutForm;
