
import React,{useEffect,useState} from 'react';
import Modal from 'react-modal';
import * as yup from "yup";
import { Button, Input, Text,SelectBox ,Img} from "components";
import useForm from "hooks/useForm";
import {  ToastContainer,toast } from "react-toastify";
import { postVenueUserData,postUserData,editProfile } from 'service/api';
import {postStates, postAddVenue,getCountry,postCities,postSingleVenue } from "service/api";
import DOB from 'components/Dob';
import EditDob from 'components/EditDob';
import Select from 'react-select';
import { useNavigate } from "react-router-dom";


import { getVenueType, postSignupOtherUser,getTimezone } from "service/api";


import "react-toastify/dist/ReactToastify.css";

const userRoleList = [
  { label: "Manager", value: 3 },
  { label: "Executive", value: 5 },
  
];
const divOptionsList = [
  { label: "America/New York", value: 1 },
  
];
const selectStyle= {
  control: (provided, state) => ({
    ...provided,
    border: "none",
     // Add optional bottom border
    backgroundColor: "transparent",
    marginBottom:0,
    boxShadow: state.isFocused ? "none" : provided.boxShadow, // Remove boxShadow on focus
   
  }),
  singleValue: (provided) => ({
    ...provided,
    color: "#fff",
  }),
  placeholder: (provided) => ({
    ...provided,
    color: "#656c79",
    fontSize: 16,
    padding:0
    
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? "#5051f9" : "#292e34",
    color: state.isSelected ? "#fafafa" : "#fff",
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    color: "#fff",
    "&:hover": {
      color: "#fff",
    },
  }),
  menu: (provided) => ({
    ...provided,
    backgroundColor: "#656c79", // Set the background color of the dropdown menu
  }),
  input: (provided, state) => ({
    ...provided,
    color: state.isFocused ? 'red' : 'inherit', // Change 'red' to your desired color
  }),

}

function ModifyUser({ isOpen, onRequestClose,id }) {
    const navigate = useNavigate();
    const [signupUser, setSignupUser] = React.useState();
   const compId=localStorage.getItem("companyId")
   const vid=localStorage.getItem("Venue")
  
  
    
    const [venueTypeList, setVenueTypeList] = useState([]);
    const [res, setRes] = useState([]);
    const [selectedVenueType, setSelectedVenueType] = useState(null);
    const [selectedUserRole, setSelectedUserRole] = useState(null);
    const [venueType, setVenueType] = React.useState();
    const[name,setName]=useState()
    const[email,setEmail]=useState()
    const[phone,setPhone]=useState()
    const[address,setAddress]=useState()
    const[zipcode,setZipcode]=useState()
     const[timezone,setTimezone]=useState()
   const[role,setRole]=useState()







   const [selectedCountry, setSelectedCountry] = useState(null);
   const [countryList, setCountryList] = useState([]);
   const [stateList, setStateList] = useState([]);
   const [loadedStateList, setLoadedStateList] = useState([]);
   const [loadedCityList, setLoadedCityList] = useState([]);
  const [selectedState, setSelectedState] = useState(null);
  const [cityList, setCityList] = useState([]);
  const [selectedCity, setSelectedCity] = useState(null);
  const [dob, setDob] = useState(null);
  

  const [resetSelectKey, setResetSelectKey] = useState(0);










    const formValidationSchema = yup.object().shape({
      firstName: yup.string().required("First Name is required"),
      lastName: yup.string().required("Last Name is required"),
      email: yup
        .string()
        .required("Email is required")
        .matches(
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/,
          "Email is not in correct format",
        ),
      password: yup.string().required("Password is required"),
      venue_name: yup.string().required("Venue Name is required"),
     
    });
    


    /////////// DropDowns ///////////

///////////Country///////////////

const handleCountryChange = (selectedOption) => {

  setSelectedCountry(selectedOption);
  states(selectedOption.value);
};

async function country() {
  const req = {};

  await getCountry(req)
    .then((res) => {
      

      let options;

      if (res.data.data.length === 1) {
        
        options = [
          {
            label: res.data.data[0].name,
            value: res.data.data[0].id,
          },
        ];
      } else {
       
        options = res.data.data.map((item) => ({
          label: item.name,
          value: item.id,
        }));
      }

      setCountryList(options);
      
    })
    .catch((err) => {
      console.error(err);
    });
}

///////////State///////////////

const handleStateChange = (selectedOption) => {

  setSelectedState(selectedOption);
  cities(selectedOption.value)
};


useEffect(() => {
  const fetchData = async () => {
    try {
     
      await country();


    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  fetchData();
}, [id]);

useEffect(() => {
  if (res) {
    // Extract state information from loaded venue data
    const states = res.map(venue => ({
      label: venue.stateinfo?.name,
      value: venue.stateinfo?.id // Assuming 'id' is the unique identifier of each state
    }));
    setLoadedStateList(states);
    // Set the selected state to "Punjab" if it exists in the state list
    const cities = res.map(venue => ({
      label: venue.cityinfo?.name,
      value: venue.cityinfo?.id // Assuming 'id' is the unique identifier of each state
    }));
    setLoadedCityList(cities);
  }
}, [res]);

async function states(selectedCountry) {
  
  const req = { 
    data:{countryId:selectedCountry }};
  try {
    const res = await postStates(req);

    let options;

    if (res.data.data.length === 1) {
      
      options = [
        {
          label: res.data.data[0].name,
          value: res.data.data[0].id,
        },
      ];
    } else {
     
      options = res.data.data.map((item) => ({
        label: item.name,
        value: item.id,
      }));
    }

    setStateList(options);
    
  } catch (error) {
    console.error("Error fetching states:", error);
  }
}
//////////////City//////////////////
const handleCityChange = (selectedOption) => {

  setSelectedCity(selectedOption);
};


async function cities(selectedState) {
  
  const req = { 
    data:{stateId:selectedState }};
  try {
    const res = await postCities(req);

    let options;

    if (res.data.data.length === 1) {
      
      options = [
        {
          label: res.data.data[0].name,
          value: res.data.data[0].id,
        },
      ];
    } else {
     
      options = res.data.data.map((item) => ({
        label: item.name,
        value: item.id,
      }));
    }

    setCityList(options);
    
  } catch (error) {
    console.error("Error fetching states:", error);
  }
}
const handleDobChange = (start) => {
  
  setDob(start);

};

    const form = useForm(
      {
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        address:"",
        venue_name: "",
        venue_type:"",
        website: "",
        timezone:"",

      },
      {
        validate: true,
        validateSchema: formValidationSchema,
        validationOnChange: true,
      },
    );



    async function loadUser() {
      const req = {
        data:{
          uid:id
        }
      };
    
      await postVenueUserData(req)
        .then((res) => {
         setRes(res.data.data)
     setName(res.data.data[0]?.name)
     setEmail(res.data.data[0]?.email)
     setPhone(res.data.data[0]?.phone)
     setAddress(res.data.data[0]?.venueinfo.address)
     setZipcode(res.data.data[0]?.userinfo.pincode)
     setTimezone(res.data.data[0]?.venueinfo.timezone)
     setRole(res.data.data[0]?.role.id)
     setDob(res.data.data[0]?.userinfo.dob)
         
        })
        .catch((err) => {
          console.error(err);
        });
    }


  
    function  update() {
      const req = {
  
        data: {
          name:name,
          uid:id,
          email:email,
          address:address,
          role: selectedUserRole?selectedUserRole.value:role,
          venueuser:1,
          phone:phone,
          pincode:zipcode,
          country_id:selectedCountry?selectedCountry.value:res[0].country_id,
          state_id:selectedState?selectedState.value:res[0].state_id,
          city_id:selectedCity?selectedCity.value:res[0].city_id,
          user_role:selectedUserRole?selectedUserRole:role,
          company_id:compId,
          venue_id:vid,
          dob:dob,
        },
  
      };
  
      editProfile(req)
        .then((res) => {
        
  
          toast.success("User Updated Succesfully!");
          setTimeout(() => {
           window.location.href = "/users";
          }, 3000);
          
        })
        .catch((err) => {
          console.error(err);
          toast.error("Something Went Wrong!");
        });
    }
    async function select() {
      const req = {};
    
      await getVenueType(req)
        .then((res) => {
          setVenueType(res.data.data);
    
          let options;
    
          if (res.data.data.length === 1) {
            
            options = [
              {
                label: res.data.data[0].name,
                value: res.data.data[0].id,
              },
            ];
          } else {
           
            options = res.data.data.map((item) => ({
              label: item.name,
              value: item.id,
            }));
          }
    
          setVenueTypeList(options);
        })
        .catch((err) => {
          console.error(err);
        });
    }
    
  
  useEffect(()=>{
    select();
   
  },[])
  
  useEffect(()=>{
    loadUser()
  },[id])

  
  
  
  const handleVenueTypeChange = (selectedOption) => {
  
    setSelectedVenueType(selectedOption);
  };
  
  const handleUserRoleChange = (selectedOption) => {
  
    setSelectedUserRole(selectedOption);
  };
  
    
    const [timezoneType, setTimezoneType] = React.useState();
    const [timeZoneList, setTimeZoneList] = useState([]);
    const [selectedTimeZoneType, setSelectTimeZoneType] = useState(null);
  
    async function fetchTimezones() {
      try {
        const req = {};
        const res = await getTimezone(req);
  
        setTimezoneType(res.data.data);
  
        let options;
  
        if (res.data.data.length === 1) {
          options = [
            {
              label: res.data.data[0].name,
              value: res.data.data[0].id,
            },
          ];
        } else {
          options = res.data.data.map((item) => ({
            label: item.name,
            value: item.id,
          }));
        }
  
        setTimeZoneList(options);
      } catch (err) {
        console.error(err);
      }
    }
   
    useEffect(() => {
     
      fetchTimezones();
    }, []);
  
    const handleTimeZoneTypeChange = (selectedOption) => {
     
      setSelectTimeZoneType(selectedOption);
    };
  
  
      return (
        <Modal
          isOpen={isOpen}
          onRequestClose={onRequestClose}
          contentLabel="Example Modal"
          style={{
            overlay: {
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
            },
            content: {
              top: '50%',
              left: '50%',
              right: 'auto',
              bottom: 'auto',
              marginRight: '-50%',
              transform: 'translate(-50%, -50%)',
              background: 'none',
              border: 'none',
              padding: 0,
              overflow: 'auto',
              maxHeight: '1000px' /* Adjust the maximum height as needed */
  
            },
           
          }}
        >
          
          <div className="flex flex-col font-poppins items-center justify-start mx-auto w-full ">
           
           <div className="flex flex-col font-poppins items-center justify-start mx-auto w-full ">
             <div className="bg-no-repeat flex flex-col items-center justify-start p-10 md:p-5 w-full">
              
               <div  className="bg-[#292e34] flex flex-col items-start justify-start max-w-[716px] p-[3.5rem] rounded-[24px] w-full ">
                 <div className='text-center w-full flex justify-between items-center'>
                 <div className='text-center w-full flex justify-between items-center'>
                <div className="flex flex-col items-center justify-center w-[534px] sm:w-full">
                  <Text
                    className="md:text-3xl sm:text-[28px] text-[32px] text-white-A700 w-auto"
                    size="txtPoppins"
                  >
                    Edit User
                  </Text>
                </div>
                <Button className="modal-close" style={{color:"white",fontSize:"xx-large"}}  onClick={onRequestClose}>
            &times;
          </Button>

                </div>
              

                </div>
               
                
          
                <div className="flex sm:flex-col flex-row gap-6 items-start justify-start w-full sm:w-full">
                  <div className="flex flex-col gap-2.5 items-center justify-start w-full sm:w-full">
                    <div className="flex flex-col items-start justify-start pb-[3px] pr-[3px] w-full">
                      <Text
                     className="text-base text-[#6a717f]"
                        size="txtPoppinsSemiBold16"
                      >
                        Name
                      </Text>
                    </div>
                    <input
                      name="input"
                      placeholder=""
                      className="capitalize font-roboto p-0 placeholder-white-900 text-base text-left w-full h-[50px] pl-4 common-pointer border border-white-700_99 border-solid w-full bg-[#292e34] "
                      wrapClassName="h-12 rounded-[10px] w-[98%]"
                      onChange={(e) => {
                        setName(e.target.value);
                      }}
                      style={{ color: 'white',borderColor:"white" }}
                      value={name}
                      shape="round"
                      color="gray_400"
                      variant="outline"
                    />
                  </div>
                 
                </div>




                <div className="flex mt-[25px] sm:flex-col flex-row gap-6 items-start justify-start w-full sm:w-full">
                  <div className="flex flex-col gap-2.5 items-center justify-start w-full sm:w-full">
                    <div className="flex flex-col items-start justify-start pb-[3px] pr-[3px] w-full">
                      <Text
                      className="text-base text-[#6a717f]"
                        size="txtPoppinsSemiBold16"
                      >
                        Email
                      </Text>
                    </div>
                    <input
                      name="input_Two"
                      placeholder=""
                      className="capitalize font-roboto p-0 placeholder-white-900 text-base text-left w-full h-[50px] pl-4 common-pointer border border-white-700_99 border-solid w-full bg-[#292e34] "
                      wrapClassName="outline outline-[1px] outline-gray-400 rounded-[10px] w-full"
                      onChange={(e) => {
                        setEmail(e.target.value)
                      }}
                      errors={form?.errors?.email}
                      value={email}
                      shape="round"
                      color="white_A700"
                      variant="outline"
                      style={{ color: 'white',borderColor:"white" }}
                    ></input>
                  </div>
                 
                </div>
              

                <div className="flex mt-[25px] flex-col items-center justify-start pb-2 w-full">
                  <div className="flex flex-col gap-1 items-start justify-start w-full sm:w-full">
                    <div className="flex flex-col items-start justify-start pb-0.5 pr-0.5 w-full ">
                      <Text
                       className="text-base text-[#6a717f]"
                        size="txtPoppinsSemiBold16"
                      >
                       Contact No.
                      </Text>
                    </div>
                    <input
                      name="input_Three"
                      placeholder=""
                      className="capitalize font-roboto p-0 placeholder-white-900 text-base text-left w-full h-[50px] pl-4 common-pointer border border-white-700_99 border-solid w-full bg-[#292e34] "
                      wrapClassName="outline outline-[1px] outline-gray-400 rounded-[10px] w-full"
                      onChange={(e) => {
                        setPhone(e.target.value)
                      }}
                      style={{ color: 'white',borderColor:"white" }}
                      
                      value={phone}
                      shape="round"
                      color="white_A700"
                      variant="outline"
                    ></input>
                  </div>
                </div>

                <div className="flex mt-[25px] flex-col items-center justify-start pb-2 w-full">
                  <div className="flex flex-col gap-1 items-start justify-start w-full sm:w-full">
                    <div className="flex flex-col items-start justify-start pb-0.5 pr-0.5 w-full ">
                      <Text
                       className="text-base text-[#6a717f]"
                        size="txtPoppinsSemiBold16"
                      >
                     Country
                      </Text>
                    </div>
                    <Select
            key={resetSelectKey} // Force re-mount when key changes
            id="country"
            name="country"
            className=" capitalize font-roboto p-0 text-base text-left w-full common-pointer bg-[#292e34] p-[10px] text-[white]  border border-[white] outline-none focus:border-b-2 focus:border-[white] focus:ring-0 appearance-none"
            options={countryList}
            placeholder="Select Country..."
            isSearchable={true}
            onChange={handleCountryChange}
            value={selectedCountry?countryList.find((option) => option.value === selectedCountry):countryList.find((option) => option.value === res[0]?.userinfo?.country_id)}
            styles={selectStyle}
           ></Select>
                  </div>
                </div>
            

                <div className="flex mt-[25px] flex-col items-center justify-start pb-2 w-full">
                  <div className="flex flex-col gap-1 items-start justify-start w-full sm:w-full">
                    <div className="flex flex-col items-start justify-start pb-0.5 pr-0.5 w-full ">
                      <Text
                       className="text-base text-[#6a717f]"
                        size="txtPoppinsSemiBold16"
                      >
                       State
                      </Text>
                    </div>
                    <Select
            key={resetSelectKey} // Force re-mount when key changes
            id="state"
            name="state"
            className=" capitalize font-roboto p-0 text-base text-left w-full common-pointer bg-[#292e34] p-[10px] text-white-A700  border border-[white] outline-none focus:border-b-2 focus:border-[white] focus:ring-0 appearance-none"
            options={stateList}
            placeholder="Select State..."
            isSearchable={true}
            onChange={handleStateChange}
            value={selectedState?stateList.find((option) => option.value === selectedState):loadedStateList.find((option) => option.value === res[0]?.userinfo.state_id)}
            styles={selectStyle}
           ></Select>

                  </div>
                </div>



                <div className="flex mt-[25px] flex-col items-center justify-start pb-2 w-full">
                  <div className="flex flex-col gap-1 items-start justify-start w-full sm:w-full">
                    <div className="flex flex-col items-start justify-start pb-0.5 pr-0.5 w-full ">
                      <Text
                       className="text-base text-[#6a717f]"
                        size="txtPoppinsSemiBold16"
                      >
                       City
                      </Text>
                    </div>
                   
<Select
            key={resetSelectKey} // Force re-mount when key changes
            id="city"
            name="city"
            className=" capitalize font-roboto p-0 text-base text-left w-full common-pointer bg-[#292e34] p-[10px] text-white-A700  border border-[white] outline-none focus:border-b-2 focus:border-[white] focus:ring-0 appearance-none"
            options={cityList}
            placeholder="Select City..."
            isSearchable={true}
            onChange={handleCityChange}
            value={selectedCity?cityList.find((option) => option.value === selectedCity):loadedCityList.find((option) => option.value === res[0]?.userinfo?.city_id)}
            styles={selectStyle}
           ></Select>
                  </div>
                </div>



                <div className="flex mt-[25px] flex-col items-center justify-start pb-2 w-full">
                  <div className="flex flex-col gap-1 items-start justify-start w-full sm:w-full">
                    <div className="flex flex-col items-start justify-start pb-0.5 pr-0.5 w-full ">
                      <Text
                       className="text-base text-[#6a717f]"
                        size="txtPoppinsSemiBold16"
                      >
                       Address
                      </Text>
                    </div>
                    <input
                      name="input_Three"
                      placeholder=""
                      className="capitalize font-roboto p-0 placeholder-white-900 text-base text-left w-full h-[50px] pl-4 common-pointer border border-white-700_99 border-solid w-full bg-[#292e34] "
                      wrapClassName="outline outline-[1px] outline-gray-400 rounded-[10px] w-full"
                      onChange={(e) => {
                        setAddress(e.target.value)
                      }}
                      style={{ color: 'white',borderColor:"white" }}
                      
                      value={address}
                      shape="round"
                      color="white_A700"
                      variant="outline"
                    ></input>
                  </div>
                </div>




                <div className="flex mt-[25px] flex-col items-center justify-start pb-2 w-full">
                  <div className="flex flex-col gap-1 items-start justify-start w-full sm:w-full">
                    <div className="flex flex-col items-start justify-start pb-0.5 pr-0.5 w-full ">
                      <Text
                       className="text-base text-[#6a717f]"
                        size="txtPoppinsSemiBold16"
                      >
                       DOB
                      </Text>
                    </div>
                    <div className=" capitalize font-roboto p-0 text-base text-left w-full common-pointer bg-[#292e34] text-white-A700  outline-none focus:border-b-2 focus:border-[white] focus:ring-0 appearance-none border ">
                    <EditDob     dob={dob}  placeholder='DOB' onChange={handleDobChange}   utcOffset={new Date().getTimezoneOffset()} />
</div>
                  </div>
                </div>

                
                <div className="flex  mt-[25px] flex-col gap-1 items-start justify-start w-full sm:w-full">
                  <div className="flex flex-col items-start justify-start pb-[3px] pr-[3px] w-full">
                    <Text
                      className="text-base text-[#6a717f]"
                      size="txtPoppinsSemiBold16"
                    >
                      User Role *
                    </Text>
                  </div>
                  <div className="flex flex-col font-roboto items-center justify-start w-full">
                  <Select
            // key={resetSelectKey} // Force re-mount when key changes
            id="userRole"
            name="userRole"
            className=" capitalize font-roboto p-0 text-base text-left w-full common-pointer bg-[#292e34] p-[10px] text-[white]  border border-[white] outline-none focus:border-b-2 focus:border-[white] focus:ring-0 appearance-none"
            options={userRoleList}
            placeholder="Select Role..."
            isSearchable={true}
            onChange={handleUserRoleChange}
            value={selectedUserRole?userRoleList.find((option) => option.value === selectedUserRole):userRoleList.find((option) => option.value === role)}
            styles={selectStyle}
           ></Select>
                   
                   
                   
                
                  </div>
                </div>

                <div className="flex  mt-[25px] flex-col gap-1 items-start justify-start w-full sm:w-full">
                  <div className="flex flex-col items-start justify-start pb-[3px] pr-[3px] w-full">
                    <Text
                      className="text-base text-[#6a717f]"
                      size="txtPoppinsSemiBold16"
                    >
                      Zipcode
                    </Text>
                  </div>
                  <input
                    name="input_Four"
                    placeholder=""
                    className="capitalize font-roboto p-0 placeholder-white-900 text-base text-left w-full h-[50px] pl-4 common-pointer border border-white-700_99 border-solid w-full bg-[#292e34] "
                    wrapClassName="outline outline-[1px] outline-gray-400 rounded-[10px] w-full"
                    onChange={(e) => {
                      setZipcode(e.target.value)
                    }}
                    style={{ color: 'white',borderColor:"white" }}
                    value={zipcode}
                    shape="round"
                    color="white_A700"
                    variant="outline"
                  ></input>
                </div>
               
              
                
                

                <div className="flex flex-col items-start justify-start w-full mt-20">
                  <Button
                    className="common-pointer cursor-pointer font-bold leading-[normal] min-w-[459px] sm:min-w-full text-center text-xl w-full"
                    shape="round"
                    size="md"
                    variant="gradient"
                    color="blue_600_indigo_900"
                    onClick={() => {
                       update();
                      }}
                  >
                   Edit
                  </Button>
                </div>
              </div>
            </div>
          </div>


          </div>
        
     
      <ToastContainer />
        </Modal>
      )
}

export default ModifyUser;



