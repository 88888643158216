
import { apis } from "service";

const COMMON_URL = `https://event-backend.isdemo.in/api/v1/`;


const API_URLS = {
  GET_VENUE_TYPE: `${COMMON_URL}venueType`,
  GET_EVENT_TYPE: `${COMMON_URL}eventType`,
  GET_TIMEZONE: `${COMMON_URL}timezone`,
  GET_COUNTRIES: `${COMMON_URL}list_countries`,
  GET_EVENT: `${COMMON_URL}event`,
  POST_ACCESS:`${COMMON_URL}checkTrailExpire`,
  GET_GENERAL_EVENT: `${COMMON_URL}regular_events`,
  GET_RECURRING_EVENT: `${COMMON_URL}recurring_events`,
  GET_SINGLE_EVENT:`${COMMON_URL}event_single_list`,
  GET_SINGLE_RECURRING_EVENT:`${COMMON_URL}event_single_list_recurring`,
  GET_SETTINGS:`${COMMON_URL}generalsetting`,
  GET_SECTION: `${COMMON_URL}list_section_by_venueid`,
  GET_SECTION_LIST: `${COMMON_URL}data-table`,
  GET_LAYOUT: `${COMMON_URL}get_layout`,
  GET_LOCALSTORAGE: `${COMMON_URL}get_layout_box`,
  GET_BOOKING_LIST:`${COMMON_URL}ticketbooking`,
  POST_USER_DATA:`${COMMON_URL}getUserData`,
  VENUE_USER_DATA:`${COMMON_URL}getUserData2`,
  GET_SECTION_BY_ID:`${COMMON_URL}listsection`,
  GET_CANVAS_TABLE:`${COMMON_URL}eventTable`,
  POST_STATE:`${COMMON_URL}list_states`,
  POST_SIGNUP_USER: `${COMMON_URL}signupUser`,
  POST_SIGNUP_OTHER_USER: `${COMMON_URL}signupOtherUser`,
  POST_LOGIN_USER: `${COMMON_URL}loginUser`,
  POST_ADD_VENUE: `${COMMON_URL}venue`,
  POST_ADD_EVENT: `${COMMON_URL}event`,
  POST_CITY: `${COMMON_URL}list_cities`,
  POST_VENUE: `${COMMON_URL}venue_list_by_company`,
  POST_SECTION: `${COMMON_URL}addsection`,
  POST_TABLE: `${COMMON_URL}eventTable`,
  POST_LAYOUT: `${COMMON_URL}layout`,
  POST_SINGLE_VENUE:`${COMMON_URL}venue_detail`,
  POST_BOOK_TICKETS:`${COMMON_URL}ticketbooking`,
  POST_SETTINGS:`${COMMON_URL}generalsetting`,
  FORGET_PASSWORD:`${COMMON_URL}forgetPassword`,
  UPDATE_PASSWORD:`${COMMON_URL}updatePassword`,
  POST_USER_LIST:`${COMMON_URL}listUsersByVenueAndRole`,
  DELETE_SECTION: `${COMMON_URL}sections`,
  DELETE_TABLE: `${COMMON_URL}eventTable`,
  DELETE_EVENT: `${COMMON_URL}event_delete`,
  DELETE_VENUE: `${COMMON_URL}deletevenue`,
  DELETE_USER: `${COMMON_URL}deleteuser`,
  DELETE_BOOKING: `${COMMON_URL}deletebooking`,
  DELETE_RECURRING_EVENT: `${COMMON_URL}event_delete`,
  UPDATE_PROFILE: `${COMMON_URL}updateProfile`,
  UPDATE_SECTION: `${COMMON_URL}sections_update`,
  UPDATE_EVENT: `${COMMON_URL}events_update`,
  UPDATE_RECURRING_EVENT: `${COMMON_URL}event`,
 GET_TOTAL_EVENT:`${COMMON_URL}total-event`,
 GET_TOTAL_VENUE:`${COMMON_URL}total-venue`,
 GET_TOTAL_CUSTOMER:`${COMMON_URL}total-customer`,
 GET_TOTAL_RESERVATION:`${COMMON_URL}total-reservation`,
 GET_CHART:`${COMMON_URL}event-by-transaction`,
};




  export const getVenueType = (payload) =>
  apis.get(API_URLS.GET_VENUE_TYPE, payload);
  
  
  export const getEvent = (payload) =>
  apis.get(`${API_URLS.GET_EVENT}?venue_id=${payload.data.venue_id}`, payload);
  export const getGeneralEvent = (payload) =>
  apis.get(`${API_URLS.GET_GENERAL_EVENT}?venue_id=${payload.data.venue_id}`, payload);
  export const getRecurringEvent = (payload) =>
  apis.get(`${API_URLS.GET_RECURRING_EVENT}?venue_id=${payload.data.venue_id}`, payload);
  
  export const getCanvasTable = (payload) =>
  apis.get(`${API_URLS.GET_CANVAS_TABLE}?venue_id=${payload.data.venue_id}`, payload);
  
  export const getTimezone = (payload) =>
  apis.get(API_URLS.GET_TIMEZONE, payload);

  export const getCountry = (payload) =>
  apis.get(API_URLS.GET_COUNTRIES, payload);

  export const getEventType = (payload) =>
  apis.get(API_URLS.GET_EVENT_TYPE, payload);

  export const getSection = (payload) =>
  apis.get(`${API_URLS.GET_SECTION}?venue_id=${payload.data.venue_id}`, payload);

  export const getBookingList = (payload) =>
  apis.get(`${API_URLS.GET_BOOKING_LIST}?venue_id=${payload.data.venue_id}`, payload);


  export const getSettings = (payload) =>
  apis.get(`${API_URLS.GET_SETTINGS}?venue_id=${payload.data.venue_id}&user_id=${payload.data.user_id}`, payload);

  export const getTotalEvents = (payload) =>
  apis.get(`${API_URLS.GET_TOTAL_EVENT}?venue_id=${payload.data.venue_id}`, payload);

  export const getTotalVenues = (payload) =>
  apis.get(`${API_URLS.GET_TOTAL_VENUE}?venue_id=${payload.data.venue_id}`, payload);

  export const getTotalCustomers = (payload) =>
  apis.get(`${API_URLS.GET_TOTAL_CUSTOMER}?venue_id=${payload.data.venue_id}`, payload);

  export const getTotalReservations = (payload) =>
  apis.get(`${API_URLS.GET_TOTAL_RESERVATION}?venue_id=${payload.data.venue_id}`, payload);

  export const postAccess = (payload) =>
  apis.post(API_URLS.POST_ACCESS, {
    ...payload,
    headers: { "Content-type": "application/json", ...payload?.headers },
  });
  export const deleteVenue = (payload) =>
  apis.post(API_URLS.DELETE_VENUE, {
    ...payload,
    headers: { "Content-type": "application/json", ...payload?.headers },
  });
  export const deleteUser = (payload) =>
  apis.post(API_URLS.DELETE_USER, {
    ...payload,
    headers: { "Content-type": "application/json", ...payload?.headers },
  });
  export const deleteBooking = (payload) =>
  apis.post(API_URLS.DELETE_BOOKING, {
    ...payload,
    headers: { "Content-type": "application/json", ...payload?.headers },
  });


  export const forgetPassword = (payload) =>
  apis.post(API_URLS.FORGET_PASSWORD, {
    ...payload,
    headers: { "Content-type": "application/json", ...payload?.headers },
  });
  export const updatePassword = (payload) =>
  apis.post(API_URLS.UPDATE_PASSWORD, {
    ...payload,
    headers: { "Content-type": "application/json", ...payload?.headers },
  });

  export const getSectionList = (payload) =>
  apis.get(`${API_URLS.GET_SECTION_LIST}?venue_id=${payload.data.venue_id}`, payload);

  export const getSingleEvent = (payload) =>
  apis.get(`${API_URLS.GET_SINGLE_EVENT}?id=${payload.data.id}`, payload);
  export const getSingleRecurringEvent = (payload) =>
  apis.get(`${API_URLS.GET_SINGLE_RECURRING_EVENT}?id=${payload.data.id}`, payload);

  

  export const sectionById = (payload) =>
  apis.get(`${API_URLS.GET_SECTION_BY_ID}?id=${payload.data.id}&venue_id=${payload.data.venue_id}`, payload);

  export const getLayout = (payload) =>
  apis.get(`${API_URLS.GET_LAYOUT}?venue_id=${payload.data.venue_id}`, payload);

  export const getChart = (payload) =>
  apis.get(`${API_URLS.GET_CHART}?venue_id=${payload.data.venue_id}`, payload);

  export const getLocalstorage = (payload) =>
  apis.get(`${API_URLS.GET_LOCALSTORAGE}?venue_id=${payload.data.venue_id}`, payload);

  export const deleteSection = (payload) =>
  apis.delete(`${API_URLS.DELETE_SECTION}?id=${payload.data.id}`, payload);

  export const deleteTable = (payload) =>
  apis.delete(`${API_URLS.DELETE_TABLE}?id=${payload.data.id}`, payload);

  export const deleteEvent = (payload) =>
  apis.delete(`${API_URLS.DELETE_EVENT}?id=${payload.data.id}&venue_id=${payload.data.venue_id}`, payload);

  export const deleteRecurringEvent = (payload) =>
  apis.delete(`${API_URLS.DELETE_RECURRING_EVENT}?id=${payload.data.id}&venue_id=${payload.data.venue_id}&type="recurring"`, payload);

  export const updateSection = (payload) =>
  apis.post(`${API_URLS.UPDATE_SECTION}?id=${payload.data.id}`, payload);

  export const updateEvent = (payload) =>
  apis.post(`${API_URLS.UPDATE_EVENT}?id=${payload.data.id}&venue_id=${payload.data.venue_id}`, payload);

  export const updateRecurringEvent = (payload) =>
  apis.post(`${API_URLS.UPDATE_RECURRING_EVENT}`, payload);


  export const postCities = (payload) =>
  apis.post(API_URLS.POST_CITY, {
    ...payload,
    headers: { "Content-type": "application/json", ...payload?.headers },
  });

  export const postStates = (payload) =>
  apis.post(API_URLS.POST_STATE, {
    ...payload,
    headers: { "Content-type": "application/json", ...payload?.headers },
  });

export const postSignupUser = (payload) =>
  apis.post(API_URLS.POST_SIGNUP_USER, {
    ...payload,
    headers: { "Content-type": "application/json", ...payload?.headers },
  });

  export const postSignupOtherUser = (payload) =>
  apis.post(API_URLS.POST_SIGNUP_OTHER_USER, {
    ...payload,
    headers: { "Content-type": "application/json", ...payload?.headers },
  });
  export const editProfile = (payload) =>
  apis.post(API_URLS.UPDATE_PROFILE, {
    ...payload,
    headers: { "Content-type": "application/json", ...payload?.headers },
  });

export const postLoginUser = (payload) =>
  apis.post(API_URLS.POST_LOGIN_USER, {
    ...payload,
    headers: { "Content-type": "application/json", ...payload?.headers },
  });
  export const postUserData = (payload) =>
  apis.post(API_URLS.POST_USER_DATA, {
    ...payload,
    headers: { "Content-type": "application/json", ...payload?.headers },
  });

  export const postVenueUserData = (payload) =>
  apis.post(API_URLS.VENUE_USER_DATA, {
    ...payload,
    headers: { "Content-type": "application/json", ...payload?.headers },
  });



  export const postAddVenue = (payload) =>
  apis.post(API_URLS.POST_ADD_VENUE, {
    ...payload,
    headers: { "Content-type": "application/json", ...payload?.headers },
  });

  export const postAddEvent = (payload) =>
  apis.post(API_URLS.POST_ADD_EVENT, {
    ...payload,
    headers: { "Content-type": "application/json", ...payload?.headers },
  });

  export const postVenueList = (payload) =>
    apis.post(API_URLS.POST_VENUE, {
      ...payload,
      headers: { "Content-type": "application/json", ...payload?.headers },
    });

    export const postSection = (payload) =>
    apis.post(API_URLS.POST_SECTION, {
      ...payload,
      headers: { "Content-type": "application/json", ...payload?.headers },
    });

    export const postUserList = (payload) =>
    apis.post(API_URLS.POST_USER_LIST, {
      ...payload,
      headers: { "Content-type": "application/json", ...payload?.headers },
    });

    export const postTable = (payload) =>
    apis.post(API_URLS.POST_TABLE, {
      ...payload,
      headers: { "Content-type": "application/json", ...payload?.headers },
    });

    export const postLayout = (payload) =>
    apis.post(API_URLS.POST_LAYOUT, {
      ...payload,
      headers: { "Content-type": "application/json", ...payload?.headers },
    });
    export const postSingleVenue = (payload) =>
    apis.post(API_URLS.POST_SINGLE_VENUE, {
      ...payload,
      headers: { "Content-type": "application/json", ...payload?.headers },
    });

    export const postBookTickets = (payload) =>
    apis.post(API_URLS.POST_BOOK_TICKETS, {
      ...payload,
      headers: { "Content-type": "application/json", ...payload?.headers },
    });

  
    export const postSettings = (payload) =>
    apis.post(API_URLS.POST_SETTINGS, {
      ...payload,
      headers: { "Content-type": "application/json", ...payload?.headers },
    });