import React, { useState, useMemo, useEffect } from 'react';
import { useTable, usePagination, useGlobalFilter } from 'react-table';
import { getBookingList,postVenueList } from 'service/api';
import { MdEdit } from "react-icons/md";
import '../Custom.css';
import { css } from '@emotion/react';
import { ScaleLoader } from 'react-spinners';
import UserModal from 'pages/CreateUser';
import { RiDeleteBin5Fill } from "react-icons/ri";
import { Button, Input, Text,SelectBox ,Img} from "components";
import Indicator from '../../assets/images/down1.png'
import ModifyUser from 'pages/ModifyUser';
import DeleteVenue from 'pages/DeleteVenue';
import EditVenue from 'pages/EditVenue';
import Modal from 'pages/Modal';
const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;



const Venue = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
    const storedVenueId = localStorage.getItem('Venue');
    const [venueId, setVenueId] = useState(storedVenueId)
  const [data, setData] = useState([]);
  const [globalFilter, setGlobalFilter] = useState('');
  const [index, setIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [isUserModalOpen, setIsUserModalOpen] = useState(false);
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [selectedUserRole, setSelectedUserRole] = useState(3);
  const [selectedOptions,setSelectedOptions]=useState([])
  const [deleteVenueId,setDeleteVenueId]=useState()
  const [editVenueId,setEditVenueId]=useState()
  const [rowId,setRowId]=useState("")
  const companyId=localStorage.getItem('companyId')

  const openDeleteModal = (venueId) => {
    setDeleteVenueId(venueId)
    setIsDeleteOpen(true);
  };

  const closeDeleteModal = () => {
    setDeleteVenueId(null)
    setIsDeleteOpen(false);
    // section();
  };


  const openEditModal = (venueId) => {

    setEditVenueId(venueId)
    setIsEditOpen(true);
  };

  const closeEditModal = () => {
    setEditVenueId(null)
    setIsEditOpen(false);
    // section();
  };


  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  


 
 
const userRoleList = [
  { label: "Manager", value: 3 },
  { label: "Executive", value: 5 },
  
];
useEffect(() => {
  
  if (selectedUserRole) {
    
    const selectedOption = userRoleList.find(option => option.value === selectedUserRole);
    setSelectedOptions(selectedOption);
    setSelectedUserRole(selectedOption.value)
  }
}, [selectedUserRole]);


  useEffect(() => {
    
 if(storedVenueId!=null){
  loadBooking();
 }
    
  }, [selectedUserRole]);


  const loadBooking = async () => {
    const req = {
      data: {
     id: companyId,
      
      },
    };
    try {
      const res = await postVenueList(req);
      setData(res.data.data);
      setIsLoading(false)
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  const columns = useMemo(
    () => [
      // { Header: 'EVENT', accessor: 'event_name' },
      // {
      //   Header: 'NAME',
      //   accessor: 'first_name',
      //   Cell: ({ row }) => {
      //     const fullName = `${row.original.first_name} ${row.original.last_name}`;
      //     return <span>{fullName}</span>;
      //   },
      // },
      { Header: 'NAME', accessor: 'name' },
      { Header: 'EMAIL', accessor: 'email' },
      { Header: 'CONTACT NO', accessor: 'phone' },
      { Header: 'LOCATION', accessor: 'address' },
      { Header: 'CITY', accessor: 'city_info.name' },
      { Header: 'COUNTRY', accessor: 'country_info.name' },
      {
        Header: 'ACTION',
        accessor: 'id', // Assuming 'id' is the property containing the event ID
        Cell: ({ value }) => (
          
           <div className="flex items-center justify"  style={{ marginRight: '10px', display: 'block', margin: 'auto'}}>
            {/* <Button
            // onClick={() => handleButtonClick(value)}
            className="your-button-styles"
            color="your-button-color"
          >
            Click Me
          </Button> */}
           <button className="mx-2 text-[white] hover:text-blue-700 text-[25px]"  onClick={()=>{
  openEditModal(value)
           }   
           }>
             <MdEdit />
           </button>
           <button className="mx-2 text-[white] hover:text-red-700 text-[25px]" onClick={()=>{
            openDeleteModal(value)
           }}>
           <RiDeleteBin5Fill />
           </button>
           
         
           {/* <AddTable isTableOpen={isTableOpen} onRequestTableClose={closeTableModal} />
           <DeleteSection isOpen={isDeleteOpen} onRequestClose={closeDeleteModal} sectionId={deleteSectionId}/>
           <EditSection isOpen={isEditSectionOpen} onRequestClose={closeEditSectionModal} sectionId={editSectionId} /> */}
           </div>
        ),
      },
  
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    pageIndex: currentPageIndex,
    canNextPage,
    canPreviousPage,
    pageOptions,
    prepareRow,
    setGlobalFilter: setTableGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 10 },
    },
    useGlobalFilter,
    usePagination
  );

  const pageIndex = currentPageIndex !== undefined ? currentPageIndex : 0;

  const handleGlobalFilterChange = (e) => {
    setGlobalFilter(e.target.value);
    setTableGlobalFilter(e.target.value);
  };

  const filteredData = useMemo(
    () =>
      data.filter((row) =>
        columns.some(
          (column) =>
            row[column.id] !== undefined &&
            String(row[column.id]).toLowerCase().includes(globalFilter.toLowerCase())
        )
      ),
    [data, columns, globalFilter, pageIndex]
  );
  return (
    <>
    < div className={`relative ${isModalOpen  ? 'filter blur' : ''}`}>
   
    <div className="flex flex-col font-roboto items-center justify-start mx-auto w-full">
    <div  className="backdrop-opacity-[0.5] bg-gray-900  flex flex-col items-center justify-end   w-full">
    <div   className="flex md:flex-col flex-row  items-start justify-between mx-auto md:px-5 w-full">
    
      {isLoading ? (
        <div style={{ display: 'flex', flexDirection:"column", justifyContent: 'center', alignItems: 'center', height: 'auto', width:"100%", margin:"20px"}}>
          <ScaleLoader css={override} color={'#5051f9'} loading={isLoading} />
          <h1 style={{color:'#5051f9', fontSize:"20px"}}> Loading!</h1>
        </div>
      ) : ( 
          
            <div  className="bg-blue_gray-900_01 flex flex-col font-poppins  justify-start sm:px-5 px-[26px] rounded shadow-bs1 m-10 w-full">
      <div className="flex-1 overflow-x-auto  ">
        <div className='grid gap-x-8 gap-y-4'>

     
        <div className="flex flex-row font-poppins booking_serch items-center justify-between mt-10 ">
          <Text
            className="text-[22px] sm:text-lg text-white-A700 md:text-xl "
            size="txtPoppinsSemiBold22"
          >
    Venue List 
          </Text>
          <div className="bg-transparent  flex flex-row justify-between gap-[20px]">
         
         
         
         
         
         
         
         
         
         
            <input
              type="text"
              value={globalFilter}
              onChange={handleGlobalFilterChange}
              placeholder="Search...."
              className="w-full p-2"
            />
                  {/* <Button
                              className="cursor-pointer font-inter font-semibold leading-[normal] min-w-[128px] rounded-lg text-center text-sm "
                              color="indigo_A400"
                              size="sm"
                               onClick={openModal}
                            >
                              + Add Venue
                            </Button> */}
          </div>
        </div>
        {data.length!==0?(
        <table {...getTableProps()} className="w-full border-collapse bg-transparent text-white" style={{ color: 'white' }}>
        <thead className='bg-[#5773c9]'>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th key={column.id} className="p-4 font-poppins">
                    {column.render('Header')}
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          
          <tbody {...getTableBodyProps()}>
            {filteredData.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()} className="border-t border-[#626262] border-solid hover:bg-gray-700 bg-opacity-25 text-[14px]">
                  {row.cells.map((cell) => (
                    <td key={cell.column.id} {...cell.getCellProps()} className="p-4">
                      {cell.render('Cell')}
                    </td>
                  ))}
                </tr>
              );
            })}
            {page.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()} className="border-t border-[#626262] border-solid hover:bg-gray-700 bg-opacity-25  text-[14px]">
                  {row.cells.map((cell) => (
                    <td key={cell.column.id} {...cell.getCellProps()} className="p-4 text-center justify-center">
                      {cell.render('Cell')}
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      ):
      <div style={{ display: 'flex', flexDirection:"column", justifyContent: 'center', alignItems: 'center', height: '350px', width:"100%"}}>
      
      <h1 className='messages'> No Users Available For This Venue!</h1>
    </div>}
  
        <div className="flex-shrink-0 p-4">
        <div className="pagination mt-4 mb-10 text-end">
      <Button
    className=" ml-3 mr-3 cursor-pointer font-inter font-semibold leading-[normal] min-w-[128px] rounded-lg text-center text-sm "
    color="indigo_A400"
    size="sm"
    onClick={() => {previousPage()
      setIndex(index - 1)}}
      disabled={!canPreviousPage}
    > Previous
    
    </Button>
    
    {' '}
    <span style={{ color: 'white' }} className="ml-5 mr-5">
      Page{' '}
      <strong>
        {index + 1} of {pageOptions.length}
      </strong>{' '}
    </span>

    <Button
    className=" ml-3 mr-3 cursor-pointer font-inter font-semibold leading-[normal] min-w-[128px] rounded-lg text-center text-sm "
    color="indigo_A400"
    size="sm"
    onClick={() => {nextPage();
      setIndex(index + 1)}}
    disabled={!canNextPage}
    > Next
    
    </Button>
    
    {' '}
  </div>
        </div>
        </div>
      </div>
    </div>
    
    
    
    
    
    
    
    )
    
    
    
    
    }

</div>
    </div>
   
    <DeleteVenue isOpen={isDeleteOpen} onRequestClose={closeDeleteModal} venueId={deleteVenueId} />
    <EditVenue isOpen={isEditOpen} onClose={closeEditModal} venueId={editVenueId} />

    </div>
    </div>
    <Modal isOpen={isModalOpen} onClose={closeModal} />

    </>
  );
};

export default Venue;
