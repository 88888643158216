import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import * as yup from 'yup';
import { Button, Input, Text } from 'components';
import { updateSection, forgetPassword } from 'service/api';
import { ToastContainer, toast } from 'react-toastify';
import { RiDeleteBin5Fill } from "react-icons/ri";
import { css } from '@emotion/react';
import { ScaleLoader } from 'react-spinners';
const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;


function ForgetPassword({ isOpen, onRequestClose }) {
  const [email, setEmail] = useState('');
const [isLoading, setIsLoading] = useState(false);


function updatePassword(){

    
      
      const req = { data: { email: email } };
    
       forgetPassword(req)
        .then((res) => {
          
          toast.success("Reset password link already sent to your email!");

          setEmail("")
    
     }
    ).catch((error)=>{
      toast.error("Please Enter your Email!");
    })
    
    
    
        
    
     
    }
  









  
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Example Modal"
      style={{
        overlay: {
          backgroundColor: 'rgba(0, 0, 0, 0.9)',
        },
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
          background: 'none',
          border: 'none',
          padding: 0,
          overflow: 'auto',
        },
      }}
    >
     
      <div className="flex flex-col font-poppins items-center justify-start mx-auto w-full ">
        <div className="flex flex-col font-poppins items-center justify-start mx-auto w-full ">
          <div className="bg-no-repeat flex flex-col items-center justify-start p-10 md:p-5 w-full">
          {isLoading ? (
        <div style={{ display: 'flex', flexDirection:"column", justifyContent: 'center', alignItems: 'center', height: 'auto', width:"100%"}}>
          <ScaleLoader css={override} color={'#5051f9'} loading={isLoading} />
          
          <h1 style={{color:'#5051f9', fontSize:"20px"}}> Loading!</h1>
        </div>
      ) :(
            <div className="bg-[white] flex flex-col items-start justify-start max-w-[716px] p-[3.5rem] rounded-[24px] w-full ">
             
              <div className='text-center w-full flex justify-between items-center'>
                <div className="flex flex-col items-center justify-center w-[534px] sm:w-full">
                  <Text
                    className="md:text-3xl sm:text-[28px] text-[32px] text-[black] w-auto"
                    size="txtPoppins"
                  >
                 Reset Password
                  </Text>
                </div>
                <Button className="modal-close" style={{color:"black",fontSize:"xx-large"}}  onClick={onRequestClose}>
            &times;
          </Button>

                </div>
          

              <div className="flex flex-col items-start justify-start mt-[38px] w-full">
              <input
                  name="Email"
                  placeholder="Enter Email"
                  className="common-pointer border border-white-700_99 border-solid w-full bg-[transparent]  font-roboto p-0 placeholder-white-900 text-base text-left w-full h-[50px] pl-4 common-pointer border border-white border-solid w-full bg-[#292e34] "
                  
                //   style={{ color: 'black' , borderColor:"white"}}
                  onChange={(e) => {
                    
                    setEmail(e.target.value);
                  }}
                 value={email}
                  size="md"
                  variant="fill"
                />
              </div>

            

 




              <div className="flex flex-col items-start justify-start w-full mt-10">
                <Button
                  className="common-pointer cursor-pointer font-bold leading-[normal] min-w-[459px] sm:min-w-full text-center text-xl w-full"
                  shape="round"
                  size="md"
                  variant="gradient"
                  color="blue_600_indigo_900"
                   onClick={updatePassword}
                >
                 Reset
                </Button>
              </div>
            </div>
      )}
          </div>
        </div>
      </div>

      {/* <ToastContainer
     
      // style={{
         
      //   top: '0%',
      //   left: '80%',
      //   transform: 'translateX(-50%)',
        
        
      // }}
    /> */}
    </Modal>
  );
}

export default ForgetPassword;
