import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Mysidebar from "pages/Mysidebar";
import Dashboard from "pages/Dashboard";
import SignUp from "pages/SignUp";
import LoginScreen from "pages/LoginScreen";
import Header from "pages/Header";
import Home from "pages/Home";
import NotFound from "pages/NotFound";
import Layout from "pages/Layout";
import Modal from "pages/Modal";
import Canvas from "pages/Canvas";
import Reservation from "pages/reservation";
import ViewLayout from "pages/ViewLayout";
import TicketReservations from "pages/TicketReservations";
import Calender from "pages/Calender";
import AllEvents from "pages/AllEvents";
import RecurringEvents from "pages/RecurringEvents";
import CheckoutForm from "pages/CheckoutForm";
import Payment from "pages/Payment";
import Settingss from "pages/Settingss";
import User from "pages/User";
import Profile from "pages/Profile";
import { postAccess } from "service/api";
import axios from "axios";
import Venue from "pages/Venue";
import Charts from "pages/Charts";
import ClientList from "pages/ClientList";
const ProjectRoutes = () => {
  const[allowed,setAllowed]=useState();
  const auth = localStorage.getItem("Name");

  const vid=localStorage.getItem("Venue");
  const uid=localStorage.getItem("LoginId");
  const[subExpire,setSubExpire]=useState();

 
  useEffect(()=>{
    const access = localStorage.getItem("Access");
    setAllowed(access)
  },[auth])

  useEffect(()=>{
    const access = localStorage.getItem("Access");
    setAllowed(access)
   
  },[auth,5 * 60 * 1000])


  



  return (
    <React.Suspense fallback={<>Loading...</>}>
      <Router>

      {auth && allowed ? (
  // Render routes if both auth and allowed are true
  <Mysidebar>
           
            <Routes>
              <Route path="/" element={<Dashboard />} />
              <Route path="/*" element={<NotFound />} />
              <Route path="/canvas" element={<Canvas/>}/>
              <Route path="/reservation" element={<Reservation/>}/>
              <Route path="/view" element={<ViewLayout/>}/>
              <Route path="/tickets" element={<TicketReservations/>}/>
              <Route path="/calender" element={<Calender/>}/>
              <Route path="/allEvents" element={<AllEvents/>}/>
              <Route path="/recurringEvents" element={<RecurringEvents/>}/>
              <Route path="/checkout/:myPrice" element={<CheckoutForm/>}/>
              <Route path="/create-checkout-session" element={<CheckoutForm/>}/>
              <Route path="/venues" element={<Venue/>}/>
              <Route path="/users" element={<User/>}/>
              <Route path="/profile" element={<Profile/>}/>
              <Route path="/settingss" element={<Settingss/>}/>
             <Route path="/settings" element={<Settingss/>}/>
             <Route path="/charts" element={<Charts/>}/>
              <Route path="/clients" element={<ClientList />} />
            </Routes>
            </Mysidebar>
) : auth && !allowed =='false'? (
  <Mysidebar>
           
            <Routes>
           
            </Routes>
            </Mysidebar>
) :!auth?(
  // Render routes if auth is false
  <Routes>
          <Route path="/" element={<LoginScreen />} />
          <Route path="/signUp" element={<SignUp />} />
        </Routes>
):null}





      
      </Router>
    </React.Suspense>
  );
};

export default ProjectRoutes;
