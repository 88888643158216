import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const DateComponent = ({ dob, onChange, placeholder }) => {
  const [selectedDate, setSelectedDate] = useState(null);

  useEffect(() => {
    if (dob) {
      // Convert ISO 8601 date string to JavaScript Date object
      const parsedDate = new Date(dob);
      setSelectedDate(parsedDate);
    }
  }, [dob]);

  const handleDateChange = (date) => {
    setSelectedDate(date);
    if (onChange) {
      // Convert the selected date to ISO 8601 format before passing it to the onChange function
      onChange(date.toISOString());
    }
  };

  return (
    <DatePicker
      selected={selectedDate}
      onChange={handleDateChange}
      placeholderText={placeholder}
      dateFormat="yyyy-MM-dd"
      className="rounded-l-md px-3 py-2 border-b border-gray-300 focus:outline-none focus:border-blue-500 flex-grow"
      showTimeInput={false}
      popperClassName="light-popper"
      yearDropdownItemNumber={200}  // Adjust the number of visible years as needed
      scrollableYearDropdown  // Enable scrollable year dropdown
      dropdownMode="scroll"
    />
  );
};

export default DateComponent;
