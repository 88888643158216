import React,{useState,useEffect} from "react";
import Chart from "chart.js";
import { getChart} from 'service/api';

export default function CardLineChart() {
  const [chartData,setChartData]=useState([]);
  const [isLoading, setIsLoading] = useState(true);
  
  const savedVenueId=localStorage.getItem('Venue')
  useEffect(()=>{
    loadChart();
  },[])

  const loadChart = async()=>{

    const req={
      data:{
        venue_id:savedVenueId
      }
    }
    try {
      const res = await getChart(req);
      
    setChartData(res.data.data)
      setIsLoading(false)
      
    }
    catch {
      console.error("there is an error");
    };
      
  }
  React.useEffect(() => {
    if (!chartData.length) return; // Don't render the chart if chartData is empty
    
    const labels = chartData.map((data) => data.date);
    const datasets = [
      {
        label: new Date().getFullYear(),
        backgroundColor: "#5773c9",
        borderColor: "#5773c9",
        data: chartData.map((data) => data.total_price),
        fill: false,
      },
    ];
    var config = {
      type: "bar",
      data: {
        labels: labels,
        datasets: datasets,
      },
      options: {
        maintainAspectRatio: false,
        responsive: true,
        title: {
          display: false,
          text: "Sales Charts",
          fontColor: "white",
        },
        legend: {
          labels: {
            fontColor: "white",
          },
          align: "end",
          position: "bottom",
        },
        tooltips: {
          mode: "index",
          intersect: false,
        },
        hover: {
          mode: "nearest",
          intersect: true,
        },
        scales: {
          xAxes: [
            {
              ticks: {
                fontColor: "rgba(255,255,255,.7)",
              },
              display: true,
              scaleLabel: {
                display: false,
                labelString: "Month",
                fontColor: "white",
              },
              gridLines: {
                display: false,
                borderDash: [2],
                borderDashOffset: [2],
                color: "rgba(33, 37, 41, 0.3)",
                zeroLineColor: "rgba(0, 0, 0, 0)",
                zeroLineBorderDash: [2],
                zeroLineBorderDashOffset: [2],
              },
            },
          ],
          yAxes: [
            {
              ticks: {
                fontColor: "rgba(255,255,255,.7)",
              },
              display: true,
              scaleLabel: {
                display: false,
                labelString: "Value",
                fontColor: "white",
              },
              gridLines: {
                borderDash: [3],
                borderDashOffset: [3],
                drawBorder: false,
                color: "rgba(255, 255, 255, 0.15)",
                zeroLineColor: "rgba(33, 37, 41, 0)",
                zeroLineBorderDash: [2],
                zeroLineBorderDashOffset: [2],
              },
            },
          ],
        },
      },
    };
    var ctx = document.getElementById("line-chart");
    if (ctx) {
      new Chart(ctx, config);
    }
  }, [chartData]);
  
  return (
  
    <>
      <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-blueGray-700">
        <div className="rounded-t mb-0 px-4 py-3 bg-transparent">
          <div className="flex flex-wrap items-center">
            <div className="relative w-full max-w-full flex-grow flex-1">
              <h6 className="uppercase text-blueGray-100 mb-1 text-xs font-semibold text-[white]">
                Overview
              </h6>
              <h2 className="text-white text-xl font-semibold  text-[white]">Sales value</h2>
            </div>
          </div>
        </div>
        <div className="p-4 flex-auto">
          {/* Chart */}
          <div className="relative h-[500px]">
            <canvas id="line-chart"></canvas>
          </div>
        </div>
      </div>
    </>
   
  );
}