// Modal.js
import React ,{useState,useEffect} from 'react';
import * as yup from "yup";
import { Button, Input, Text,SelectBox ,Img} from "components";
import useForm from "hooks/useForm";
import {postStates, postAddVenue,getCountry,postCities, getVenueType,getTimezone,postSingleVenue } from "service/api";
import {  ToastContainer,toast } from "react-toastify";
import Modal from 'react-modal';
import { useNavigate } from "react-router-dom";
import "../Custom.css";
import Select from 'react-select';
import { css } from '@emotion/react';
import { ScaleLoader } from 'react-spinners';

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;




const EditVenue = ({ isOpen, onClose,venueId }) => {
  const handleCloseModal = () => {
    // Reset the form when closing the modal
    form.resetForm();
    
    // Close the modal
    onClose();
    setResetSelectKey((prevKey) => prevKey + 1);
  };
  
 const cid= localStorage.getItem("companyId");

const [name, setName] = useState();
const [email, setEmail] = useState(null);
const [phone, setPhone] = useState(null);
const [zipcode, setZipcode] = useState(null);
const [address, setAddress] = useState(null);
const [tax, setTax] = useState(null);
const [website, setWebsite] = useState(null);
// const [currency, setCurrency] = useState(null);
const [capacity, setCapacity] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [countryList, setCountryList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [loadedStateList, setLoadedStateList] = useState([]);
  const [loadedCityList, setLoadedCityList] = useState([]);
 const [selectedState, setSelectedState] = useState(null);
 const [cityList, setCityList] = useState([]);
 const [selectedCity, setSelectedCity] = useState(null);
 const [venueTypeList, setVenueTypeList] = useState([]);
 const [selectedVenueType, setSelectedVenueType] = useState(null);
 const [timezoneList, setTimezoneList] = useState([]);
 const [selectedTimezone, setSelectedTimezone] = useState(null);
 const [resetSelectKey, setResetSelectKey] = useState(0);
 const [isLoading, setIsLoading] = useState(false);
  const[loadedVenue,setLoadedVenue]=useState([])
  const[venueData,setVenueData]=useState([])
      const formValidationSchema = yup.object().shape({
      name: yup.string().required("Name is required"),
      email: yup
      .string()
      .required("Email is required")
      .matches(
      /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/,
      "Email is not in correct format",
      ),
      phone: yup
      .string()
      .required("Contact Number is required")
      .matches(/^\d{10}$/, "Contact Number must be 10 digits"),
     
      zipcode: yup.string().required("Zipcode is required"),
      address: yup.string().required("Address is required"),
      // venue_type: yup.string().required("Venue Type is required"),
      // website: yup.string().required("Website is required"),
      // currency: yup.string().required("Currency is required"),
      // capacity: yup.string().required("Capacity is required")

      });

      const form = useForm(
        {
          name: "",
          email: "",
          phone: "",
          country_id:"",
          state_id: "",
          city_id:"",
          zipcode: "",
          address: "",
          tax: "",
          venue_type: "",
          timezone:"",
          website: "",
          
          capacity: "",
        },
        {
          validate: true,
          validateSchema: formValidationSchema,
          validationOnChange: true,
        },
      );

     useEffect(() => {
  const fetchData = async () => {
    try {
     
      await country();
      await venueType();
      await timezone();

    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  fetchData();
}, []);



useEffect(()=>{
  loadvenue();
},[venueId])

const selectStyle= {
  control: (provided, state) => ({
    ...provided,
    border: "none",
     // Add optional bottom border
    backgroundColor: "transparent",
    marginBottom:0,
    boxShadow: state.isFocused ? "none" : provided.boxShadow, // Remove boxShadow on focus
   
  }),
  singleValue: (provided) => ({
    ...provided,
    color: "#fff",
  }),
  placeholder: (provided) => ({
    ...provided,
    color: "#656c79",
    fontSize: 16,
    padding:0
    
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? "#5051f9" : "#292e34",
    color: state.isSelected ? "#fafafa" : "#fff",
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    color: "#fff",
    "&:hover": {
      color: "#fff",
    },
  }),
  menu: (provided) => ({
    ...provided,
    backgroundColor: "#656c79", // Set the background color of the dropdown menu
  }),
  input: (provided, state) => ({
    ...provided,
    color: state.isFocused ? 'red' : 'inherit', // Change 'red' to your desired color
  }),

}

useEffect(()=>{
setVenueData(loadedVenue)
},[loadedVenue])


async function loadvenue() {
  const req = {
    data:{
      id:venueId,
    }
  };

  await postSingleVenue(req)
    .then((res) => {
      
  setLoadedVenue(res.data.data);
   setName(res.data.data[0]?.name)
   setEmail(res.data.data[0]?.email)
   setPhone(res.data.data[0]?.phone)
   setZipcode(res.data.data[0]?.zipcode)
   setAddress(res.data.data[0]?.address)
   setTax(res.data.data[0]?.tax)
   setWebsite(res.data.data[0]?.website)
  //  setCurrency(res.data.data[0].currency)
   setCapacity(res.data.data[0]?.capacity)
     
      
    })
    .catch((err) => {
      console.error(err);
    });
}






     async function addvenue() {

        const req = {
    
          data: {
            name: name,
            email: email,
            phone: phone,
            cid: cid,
            country_id:selectedCountry?selectedCountry.value:loadedVenue[0].country_id,
            state_id:selectedState?selectedState.value:loadedVenue[0].state_id,
            city_id:selectedCity?selectedCity.value:loadedVenue[0].city_id,
            zipcode: zipcode,
            address: address,
            tax: tax,
            venue_type:selectedVenueType?selectedVenueType.value:loadedVenue[0].venue_type,
            timezone:selectedTimezone?selectedTimezone.value:loadedVenue[0].timezone,
            website:website,
            // currency:currency,
            capacity:capacity,
            id:venueId,
    
          },
    
        };
     await   postAddVenue(req)
          .then((res) => {
            
        
            setIsLoading(false)
            toast.success("Venue is updated Succesfully!");
            setTimeout(() => {
              window.location.href="/venues"
            }, 3000);
          
          })
          .catch((err) => {
            console.error(err);
            toast.error("Something Went Wrong!");
          });
      }
     
     


      

/////////// DropDowns ///////////

///////////Country///////////////

const handleCountryChange = (selectedOption) => {

  setSelectedCountry(selectedOption);
  states(selectedOption.value);
};

async function country() {
  const req = {};

  await getCountry(req)
    .then((res) => {
      

      let options;

      if (res.data.data.length === 1) {
        
        options = [
          {
            label: res.data.data[0]?.name,
            value: res.data.data[0]?.id,
          },
        ];
      } else {
       
        options = res.data.data.map((item) => ({
          label: item.name,
          value: item.id,
        }));
      }

      setCountryList(options);
      
    })
    .catch((err) => {
      console.error(err);
    });
}

///////////State///////////////

const handleStateChange = (selectedOption) => {

  setSelectedState(selectedOption);
  cities(selectedOption.value)
};


useEffect(() => {
  if (loadedVenue) {
    // Extract state information from loaded venue data
    const states = loadedVenue.map(venue => ({
      label: venue.state_info?.name,
      value: venue.state_info?.id // Assuming 'id' is the unique identifier of each state
    }));
    setLoadedStateList(states);
    // Set the selected state to "Punjab" if it exists in the state list
    const cities = loadedVenue.map(venue => ({
      label: venue.city_info?.name,
      value: venue.city_info?.id // Assuming 'id' is the unique identifier of each state
    }));
    setLoadedCityList(cities);
  }
}, [loadedVenue]);

async function states(selectedCountry) {
  
  const req = { 
    data:{countryId:selectedCountry }};
  try {
    const res = await postStates(req);

    let options;

    if (res.data.data.length === 1) {
      
      options = [
        {
          label: res.data.data[0]?.name,
          value: res.data.data[0]?.id,
        },
      ];
    } else {
     
      options = res.data.data.map((item) => ({
        label: item.name,
        value: item.id,
      }));
    }

    setStateList(options);
    
  } catch (error) {
    console.error("Error fetching states:", error);
  }
}
//////////////City//////////////////
const handleCityChange = (selectedOption) => {

  setSelectedCity(selectedOption);
};


async function cities(selectedState) {
  
  const req = { 
    data:{stateId:selectedState }};
  try {
    const res = await postCities(req);

    let options;

    if (res.data.data.length === 1) {
      
      options = [
        {
          label: res.data.data[0]?.name,
          value: res.data.data[0]?.id,
        },
      ];
    } else {
     
      options = res.data.data.map((item) => ({
        label: item.name,
        value: item.id,
      }));
    }

    setCityList(options);
    
  } catch (error) {
    console.error("Error fetching states:", error);
  }
}


//////////Venue Type///////


const handleVenueTypeChange = (selectedOption) => {

  setSelectedVenueType(selectedOption);
 
};

async function venueType() {
  const req = {};

  await getVenueType(req)
    .then((res) => {
      

      let options;

      if (res.data.data.length === 1) {
        
        options = [
          {
            label: res.data.data[0]?.name,
            value: res.data.data[0]?.id,
          },
        ];
      } else {
       
        options = res.data.data.map((item) => ({
          label: item.name,
          value: item.id,
        }));
      }

      setVenueTypeList(options);
      
    })
    .catch((err) => {
      console.error(err);
    });
}
//////////Timezone///////


const handleTimezoneChange = (selectedOption) => {

  setSelectedTimezone(selectedOption);
 
};

async function timezone() {
  const req = {};

  await getTimezone(req)
    .then((res) => {
      

      let options;

      if (res.data.data.length === 1) {
        
        options = [
          {
            label: res.data.data[0]?.name,
            value: res.data.data[0]?.id,
          },
        ];
      } else {
       
        options = res.data.data.map((item) => ({
          label: item.name,
          value: item.id,
        }));
      }

      setTimezoneList(options);
      
    })
    .catch((err) => {
      console.error(err);
    });
}






return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      contentLabel="Example Modal"
      style={{
        overlay: {
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
        },
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
          background: 'none',
          border: 'none',
          padding: 0,
          overflow: 'auto',
          height:'900px'
        },
      }}
    >
     
      <div className="flex flex-col font-poppins items-center justify-start mx-auto w-full ">
        <div className="flex flex-col font-poppins items-center justify-start mx-auto w-full ">
          <div className="bg-no-repeat flex flex-col items-center justify-start p-10 md:p-5 w-full">
          {isLoading ? (
        <div style={{ display: 'flex', flexDirection:"column", justifyContent: 'center', alignItems: 'center', height: 'auto', width:"100%"}}>
          <ScaleLoader css={override} color={'#5051f9'} loading={isLoading} />
          
          <h1 style={{color:'#5051f9', fontSize:"20px"}}> Loading!</h1>
        </div>
      ) :(
            <div className="bg-[#292e34] flex flex-col items-start justify-start max-w-[716px] p-[3.5rem] rounded-[24px] w-full ">
             
              <div className='text-center w-full flex justify-between items-center'>
                <div className="flex flex-col items-center justify-center w-[534px] sm:w-full">
                  <Text
                    className="md:text-3xl sm:text-[28px] text-[32px] text-white-A700 w-auto"
                    size="txtPoppins"
                  >
                    Edit Venue
                  </Text>
                </div>
                <Button className="modal-close" style={{color:"white",fontSize:"xx-large"}}  onClick={onClose}>
            &times;
          </Button>

                </div>
          
                <div className="flex flex-col items-start justify-start mt-[38px] w-full">
                  <input
                        name="input"
                        placeholder="Name"
                        className="capitalize font-roboto p-0 placeholder-white-900 text-base text-left w-full h-[50px] pl-4 common-pointer border border-white-700_99 border-solid w-full bg-[#292e34] "
                        wrapClassName="common-pointer border-b border-white-700_99 border-solid w-full bg-[#292e34]"
                        style={{ color: 'white',borderColor:"white" }}
                        onChange={(e) => {
                          setName(e.target.value);
                        }}
                   
                        value={name}
                      
                        size="md"
                        variant="fill"
                />

                  {/* {/ Add more input fields as needed /} */}
                </div>
               
                <div className="flex flex-col items-start justify-start mt-[38px] w-full">
                  <input
                    name="input"
                    placeholder="Email"
                    className="capitalize font-roboto p-0 placeholder-white-900 text-base text-left w-full h-[50px] pl-4 common-pointer border border-white-700_99 border-solid w-full bg-[#292e34] "
                    type="email"
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                    errors={form?.errors?.email}
                    value={email}
                    style={{ color: 'white',borderColor:"white" }}
                   
                    size="md"
                    variant="fill"
                  />
                  {/* {/ Add more input fields as needed /} */}
                </div>
                <div className="flex flex-col items-start justify-start mt-[38px] w-full">
                  <input
                    name="input"
                    placeholder="Contact No"
                    className="capitalize font-roboto p-0 placeholder-white-900 text-base text-left w-full h-[50px] pl-4 common-pointer border border-white-700_99 border-solid w-full bg-[#292e34] "
                    wrapClassName="common-pointer border-b border-white-700_99 border-solid w-full bg-[#292e34]"
                    type="tel"
                    onChange={(e) => {
                      setPhone(e.target.value);
                    }}
                    errors={form?.errors?.phone}
                    value={phone}
                    style={{ color: 'white',borderColor:"white" }}
                   
                    size="md"
                    variant="fill"
                  />
                  {/* {/ Add more input fields as needed /} */}
                </div>
                
                <div className="flex flex-col  items-start justify-start mt-[38px] w-full text-[white]">
                {/* <select
  id="country"
  name="country"
  className="capitalize font-roboto p-0 placeholder:text-gray-500 text-base text-left w-full common-pointer bg-[#292e34] p-[18px] text-white-A700 border-t-0 border-r-0 border-l-0 border-b-2 border-[white] outline-none focus:border-b-2 focus:border-[white] focus:ring-0 appearance-none"
  onChange={(e) => {
    form.handleChange("country_id", e.target.value);
    states(e.target.value);
  }}
  value={form.values.country_id}
  
  

>
  <option value="" disabled hidden>Select Country...</option>
  {countryList.map((country) => (
    <option key={country.value} value={country.value}>
      {country.label}
    </option>
  ))}

</select> */}


<Select
            key={resetSelectKey} // Force re-mount when key changes
            id="country"
            name="country"
            className=" capitalize font-roboto p-0 text-base text-left w-full common-pointer bg-[#292e34] p-[10px] text-[white]  border border-[white] outline-none focus:border-b-2 focus:border-[white] focus:ring-0 appearance-none"
            options={countryList}
            placeholder="Select Country..."
            isSearchable={true}
            onChange={handleCountryChange}
            value={selectedCountry?countryList.find((option) => option.value === selectedCountry):countryList.find((option) => option.value === loadedVenue[0]?.country_id)}
            styles={selectStyle}
           ></Select>

 </div>
                
               
                <div className="flex flex-col items-start justify-start mt-[38px] w-full">
                
                 {/* <select
  id="state"
  name="state"
  className="capitalize font-roboto p-0 placeholder:text-gray-500 text-base text-left w-full common-pointer bg-[#292e34] p-[18px] text-white-A700 border-t-0 border-r-0 border-l-0 border-b-2 border-[white] outline-none focus:border-b-2 focus:border-[white] focus:ring-0 appearance-none"
  onChange={(e) => {
    form.handleChange("state_id", e.target.value);
    cities(e.target.value);
  }}
  value={form.values.state_id}
  
  

>
  <option value="" disabled hidden>Select State...</option>
  {stateList.map((state) => (
    <option key={state.value} value={state.value}>
      {state.label}
    </option>
  ))}

</select> */}

<Select
            key={resetSelectKey} // Force re-mount when key changes
            id="state"
            name="state"
            className=" capitalize font-roboto p-0 text-base text-left w-full common-pointer bg-[#292e34] p-[10px] text-white-A700  border border-[white] outline-none focus:border-b-2 focus:border-[white] focus:ring-0 appearance-none"
            options={stateList}
            placeholder="Select State..."
            isSearchable={true}
            onChange={handleStateChange}
            value={selectedState?stateList.find((option) => option.value === selectedState):loadedStateList.find((option) => option.value === loadedVenue[0]?.state_id)}
            styles={selectStyle}
           ></Select>


                </div>

                <div className="flex flex-col items-start justify-start mt-[38px] w-full">
               




<Select
            key={resetSelectKey} // Force re-mount when key changes
            id="city"
            name="city"
            className=" capitalize font-roboto p-0 text-base text-left w-full common-pointer bg-[#292e34] p-[10px] text-white-A700  border border-[white] outline-none focus:border-b-2 focus:border-[white] focus:ring-0 appearance-none"
            options={cityList}
            placeholder="Select City..."
            isSearchable={true}
            onChange={handleCityChange}
            value={selectedCity?cityList.find((option) => option.value === selectedCity):loadedCityList.find((option) => option.value === loadedVenue[0]?.city_id)}
            styles={selectStyle}
           ></Select>



                </div>
                <div className="flex flex-col items-start justify-start mt-[38px] w-full">
                  <input
                    name="input"
                    placeholder="Zipcode"
                    className="capitalize font-roboto p-0 placeholder-white-900 text-base text-left w-full h-[50px] pl-4 common-pointer border border-white-700_99 border-solid w-full bg-[#292e34] "
                    wrapClassName="common-pointer border-b border-white-700_99 border-solid w-full bg-[#292e34]"
                    
                    onChange={(e) => {
                      setZipcode(e.target.value);
                    }}
                    errors={form?.errors?.zipcode}
                    value={zipcode}
                    style={{ color: 'white',borderColor:"white" }}
                    size="md"
                    variant="fill"
                  />
                  
                </div>
                <div className="flex flex-col items-start justify-start mt-[38px] w-full">
                  <input
                    name="input"
                    placeholder="Address"
                    className="capitalize font-roboto p-0 placeholder-white-900 text-base text-left w-full h-[50px] pl-4 common-pointer border border-white-700_99 border-solid w-full bg-[#292e34] "
                    wrapClassName="common-pointer border-b border-white-700_99 border-solid w-full bg-[#292e34]"
                    
                    onChange={(e) => {
                      setAddress(e.target.value);
                    }}
                    errors={form?.errors?.address}
                    value={address}
                    style={{ color: 'white',borderColor:"white" }}
                    size="md"
                    variant="fill"
                  />
                  
                </div>
                <div className="flex flex-col items-start justify-start mt-[38px] w-full">
                  <input
                    name="input"
                    placeholder="Tax"
                    className="capitalize font-roboto p-0 placeholder-white-900 text-base text-left w-full h-[50px] pl-4 common-pointer border border-white-700_99 border-solid w-full bg-[#292e34] "
                    wrapClassName="common-pointer border-b border-white-700_99 border-solid w-full bg-[#292e34]"
                    
                    onChange={(e) => {
                      setTax(e.target.value);
                    }}
                    errors={form?.errors?.tax}
                    value={tax}
                    style={{ color: 'white',borderColor:"white" }}
                    size="md"
                    variant="fill"
                  />
                 
                </div>

                <div className="flex flex-col items-start justify-start mt-[38px] w-full">
               
        

          <Select
            key={resetSelectKey} // Force re-mount when key changes
            id="venue_type"
            name="venue_type"
            className=" capitalize font-roboto p-0 text-base text-left w-full common-pointer bg-[#292e34] p-[10px] text-white-A700  border border-[white] outline-none focus:border-b-2 focus:border-[white] focus:ring-0 appearance-none"
            options={venueTypeList}
            placeholder="Select Venue Type ..."
            isSearchable={true}
            onChange={handleVenueTypeChange}
            value={selectedVenueType?venueTypeList.find((option) => option.value === selectedVenueType):venueTypeList.find((option) => option.value === loadedVenue[0]?.venue_type)}
            styles={selectStyle}
           ></Select>

                </div>
                <div className="flex flex-col items-start justify-start mt-[38px] w-full">
              

          

              <Select
            key={resetSelectKey} // Force re-mount when key changes
            id="timezone"
              name="timezone"
            className=" capitalize font-roboto p-0 text-base text-left w-full common-pointer bg-[#292e34] p-[10px] text-white-A700  border border-[white] outline-none focus:border-b-2 focus:border-[white] focus:ring-0 appearance-none"
            options={timezoneList}
            placeholder="Select Timezone ..."
            isSearchable={true}
            onChange={handleTimezoneChange}
            value={selectedTimezone?timezoneList.find((option) => option.value === selectedTimezone):timezoneList.find((option) => option.value === loadedVenue[0]?.timezone)}
            styles={selectStyle}
           ></Select>



                </div>
              
                <div className="flex flex-col items-start justify-start mt-[38px] w-full">
                  <input
                    name="input"
                    placeholder="Website"
                    className="capitalize font-roboto p-0 placeholder-white-900 text-base text-left w-full h-[50px] pl-4 common-pointer border border-white-700_99 border-solid w-full bg-[#292e34] "
                    wrapClassName="common-pointer border-b border-white-700_99 border-solid w-full bg-[#292e34]"
                    
                    onChange={(e) => {
                      setWebsite(e.target.value);
                    }}
                    errors={form?.errors?.website}
                    value={website}
                    style={{ color: 'white',borderColor:"white" }}
                    size="md"
                    variant="fill"
                  />
                  
                </div>
                {/* <div className="flex flex-col items-start justify-start mt-[38px] w-full">
                  <input
                    name="input"
                    placeholder="Currency"
                    className="capitalize font-roboto p-0 placeholder-white-900 text-base text-left w-full h-[50px] pl-4 common-pointer border border-white-700_99 border-solid w-full bg-[#292e34] "
                    wrapClassName="common-pointer border-b border-white-700_99 border-solid w-full bg-[#292e34]"
                    
                    onChange={(e) => {
                      setCurrency(e.target.value);
                    }}
                    errors={form?.errors?.currency}
                    value={currency}
                    style={{ color: 'white',borderColor:"white" }}
                    size="md"
                    variant="fill"
                  />
                  
                </div> */}
                <div className="flex flex-col items-start justify-start mt-[38px] w-full">
                  <input
                    name="input"
                    placeholder="Capacity"
                     className="capitalize font-roboto p-0 placeholder-white-900 text-base text-left w-full h-[50px] pl-4 common-pointer border border-white-700_99 border-solid w-full bg-[#292e34] "
                    wrapClassName="common-pointer border-b border-white-700_99 border-solid w-full bg-[#292e34]"
                    
                    onChange={(e) => {
                      setCapacity(e.target.value);
                    }}
                    errors={form?.errors?.capacity}
                    value={capacity}
                    style={{ color: 'white',borderColor:"white" }}
                    size="md"
                    variant="fill"
                  />
                  
                </div>
                

                <div className="flex flex-col items-start justify-start w-full mt-20">
                  <Button
                    className="common-pointer cursor-pointer font-bold leading-[normal] min-w-[459px] sm:min-w-full text-center text-xl w-full"
                    shape="round"
                    size="md"
                    variant="gradient"
                    color="blue_600_indigo_900"
                    onClick={() => {
                      addvenue()
                    }}
                  >
                  UPDATE
                  </Button>
                </div>













            </div>
      )}
          </div>
        </div>
      </div>

      <ToastContainer
     
      // style={{
         
      //   top: '0%',
      //   left: '80%',
      //   transform: 'translateX(-50%)',
        
        
      // }}
    />
    </Modal>
  );
}
      
    
   
    
 

export default EditVenue;