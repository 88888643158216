
import React,{useEffect,useState} from 'react';
import Modal from 'react-modal';
import * as yup from "yup";
import { Button, Input, Text,SelectBox ,Img} from "components";
import useForm from "hooks/useForm";
import {  ToastContainer,toast } from "react-toastify";
import { postSection } from 'service/api';
import Select from 'react-select';
import { useNavigate } from "react-router-dom";
import DOB from 'components/Dob';

import { postStates, postAddVenue,getCountry,postCities, getVenueType, postSignupOtherUser,getTimezone } from "service/api";


import "react-toastify/dist/ReactToastify.css";





function CreateUser({ isOpen, onRequestClose }) {
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [countryList, setCountryList] = useState([]);
  const [stateList, setStateList] = useState([]);
 const [selectedState, setSelectedState] = useState(null);
 const [cityList, setCityList] = useState([]);
 const [selectedCity, setSelectedCity] = useState(null);
 const [resetSelectKey, setResetSelectKey] = useState(0);
    const navigate = useNavigate();
    const [signupUser, setSignupUser] = React.useState();
   const compId=localStorage.getItem("companyId")
   const vid=localStorage.getItem("Venue")
   const [dob, setDob] = useState(null);
  
    
    const [venueTypeList, setVenueTypeList] = useState([]);
    const [selectedVenueType, setSelectedVenueType] = useState(null);
    const [selectedUserRole, setSelectedUserRole] = useState(null);
    const [venueType, setVenueType] = React.useState();
    
    const formValidationSchema = yup.object().shape({
      firstName: yup.string().required("First Name is required"),
      lastName: yup.string().required("Last Name is required"),
      email: yup
        .string()
        .required("Email is required")
        .matches(
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/,
          "Email is not in correct format",
        ),
      password: yup.string().required("Password is required"),
      
     
    });
    



    useEffect(() => {
      const fetchData = async () => {
        try {
          await country();
          
          
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
    
      fetchData();
    }, []);
    
    const selectStyle= {
      control: (provided, state) => ({
        ...provided,
        border: "1px solid #c9c9c9",
        borderRadius:"10px",
        height:"51px",
         // Add optional bottom border
        backgroundColor: "transparent",
        marginBottom:0,
        boxShadow: state.isFocused ? "none" : provided.boxShadow, // Remove boxShadow on focus
       
      }),
      singleValue: (provided) => ({
        ...provided,
        color: "#fff",
      }),
      placeholder: (provided) => ({
        ...provided,
        color: "#656c79",
        fontSize: 16,
        padding:0
        
      }),
      option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isSelected ? "#5051f9" : "#292e34",
        color: state.isSelected ? "#fafafa" : "#fff",
      }),
      dropdownIndicator: (provided) => ({
        ...provided,
        color: "#fff",
        "&:hover": {
          color: "#fff",
        },
      }),
      menu: (provided) => ({
        ...provided,
        backgroundColor: "#656c79", // Set the background color of the dropdown menu
      }),
      input: (provided, state) => ({
        ...provided,
        color: state.isFocused ? 'red' : 'inherit', // Change 'red' to your desired color
      }),
    
    }
    /////////// DropDowns ///////////
    
    ///////////Country///////////////
    
    const handleCountryChange = (selectedOption) => {
    
      setSelectedCountry(selectedOption);
      states(selectedOption.value);
    };
    
    async function country() {
      const req = {};
    
      await getCountry(req)
        .then((res) => {
          
    
          let options;
    
          if (res.data.data.length === 1) {
            
            options = [
              {
                label: res.data.data[0].name,
                value: res.data.data[0].id,
              },
            ];
          } else {
           
            options = res.data.data.map((item) => ({
              label: item.name,
              value: item.id,
            }));
          }
    
          setCountryList(options);
          
        })
        .catch((err) => {
          console.error(err);
        });
    }
    
    ///////////State///////////////
    
    const handleStateChange = (selectedOption) => {
    
      setSelectedState(selectedOption);
      cities(selectedOption.value)
    };
    
    
    async function states(selectedCountry) {
      
      const req = { 
        data:{countryId:selectedCountry }};
      try {
        const res = await postStates(req);
    
        let options;
    
        if (res.data.data.length === 1) {
          
          options = [
            {
              label: res.data.data[0].name,
              value: res.data.data[0].id,
            },
          ];
        } else {
         
          options = res.data.data.map((item) => ({
            label: item.name,
            value: item.id,
          }));
        }
    
        setStateList(options);
        
      } catch (error) {
        console.error("Error fetching states:", error);
      }
    }
    //////////////City//////////////////
    const handleCityChange = (selectedOption) => {
    
      setSelectedCity(selectedOption);
    };
    
    
    async function cities(selectedState) {
      
      const req = { 
        data:{stateId:selectedState }};
      try {
        const res = await postCities(req);
    
        let options;
    
        if (res.data.data.length === 1) {
          
          options = [
            {
              label: res.data.data[0].name,
              value: res.data.data[0].id,
            },
          ];
        } else {
         
          options = res.data.data.map((item) => ({
            label: item.name,
            value: item.id,
          }));
        }
    
        setCityList(options);
        
      } catch (error) {
        console.error("Error fetching states:", error);
      }
    }
    
    
    
    
    const userRoleList = [
      { label: "Manager", value: 3 },
      { label: "Executive", value: 5 },
      
    ];
    const divOptionsList = [
      { label: "America/New York", value: 1 },
      
    ];
    






    const handleDobChange = (start) => {
  
      setDob(start);
    
    };



    const form = useForm(
      {
        firstName: "",
        lastName: "",
        email: "",
        phone:"",
        password: "",
        address:"",
   
        zipcode: "",
        
      },
      {
        validate: true,
        validateSchema: formValidationSchema,
        validationOnChange: true,
      },
    );
  
    function createanaccount(data) {
      const req = {
  
        data: {
          firstName: data?.firstName,
          lastName: data?.lastName,
          email: data?.email,
          password: data?.password,
          phone:data?.phone,
          country_id:selectedCountry.value,
          state_id:selectedState.value,
          city_id:selectedCity.value,
          role_id:selectedUserRole,
          company_id:compId,
          venue_id:vid,
          pincode:data?.zipcode,
          dob:dob,
        },
  
      };
  
      postSignupOtherUser(req)
        .then((res) => {
          setSignupUser(res?.data);
  
          toast.success("You are Registered Succesfully!");
          setTimeout(() => {
              window.location.href = "/users";
          }, 3000);
          
        })
        .catch((err) => {
          console.error(err);
          toast.error("Something Went Wrong!");
        });
    }
    async function select() {
      const req = {};
    
      await getVenueType(req)
        .then((res) => {
          setVenueType(res.data.data);
    
          let options;
    
          if (res.data.data.length === 1) {
            
            options = [
              {
                label: res.data.data[0].name,
                value: res.data.data[0].id,
              },
            ];
          } else {
           
            options = res.data.data.map((item) => ({
              label: item.name,
              value: item.id,
            }));
          }
    
          setVenueTypeList(options);
        })
        .catch((err) => {
          console.error(err);
        });
    }
    
  
  useEffect(()=>{
    select();
  
  },[])
  
  
  
  
  const handleVenueTypeChange = (selectedOption) => {
  
    setSelectedVenueType(selectedOption);
  };
  
  const handleUserRoleChange = (selectedOption) => {
  
    setSelectedUserRole(selectedOption);
  };
  
    
    const [timezoneType, setTimezoneType] = React.useState();
    const [timeZoneList, setTimeZoneList] = useState([]);
    const [selectedTimeZoneType, setSelectTimeZoneType] = useState(null);
  
    async function fetchTimezones() {
      try {
        const req = {};
        const res = await getTimezone(req);
  
        setTimezoneType(res.data.data);
  
        let options;
  
        if (res.data.data.length === 1) {
          options = [
            {
              label: res.data.data[0].name,
              value: res.data.data[0].id,
            },
          ];
        } else {
          options = res.data.data.map((item) => ({
            label: item.name,
            value: item.id,
          }));
        }
  
        setTimeZoneList(options);
      } catch (err) {
        console.error(err);
      }
    }
   
    useEffect(() => {
     
      fetchTimezones();
    }, []);
  
    const handleTimeZoneTypeChange = (selectedOption) => {
     
      setSelectTimeZoneType(selectedOption);
    };
  
  
              
      return (
        <Modal
          isOpen={isOpen}
          onRequestClose={onRequestClose}
          contentLabel="Example Modal"
          style={{
            overlay: {
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
            },
            content: {
              top: '50%',
              left: '50%',
              right: 'auto',
              bottom: 'auto',
              marginRight: '-50%',
              transform: 'translate(-50%, -50%)',
              background: 'none',
              border: 'none',
              padding: 0,
              overflow: 'auto',
              maxHeight: '1000px' /* Adjust the maximum height as needed */
  
            },
           
          }}
        >
          
          <div className="flex flex-col font-poppins items-center justify-start mx-auto w-full ">
           
           <div className="flex flex-col font-poppins items-center justify-start mx-auto w-full ">
             <div className="bg-no-repeat flex flex-col items-center justify-start p-10 md:p-5 w-full">
               <div  className="bg-[#292e34] flex flex-col items-start justify-start max-w-[716px] p-[3.5rem] rounded-[24px] w-full ">
                 <div className='text-center w-full flex justify-between items-center'>
                 <div className='text-center w-full flex justify-between items-center'>
                <div className="flex flex-col items-center justify-center w-[534px] sm:w-full">
                  <Text
                    className="md:text-3xl sm:text-[28px] text-[32px] text-white-A700 w-auto"
                    size="txtPoppins"
                  >
                    Create User
                  </Text>
                </div>
                <Button className="modal-close" style={{color:"white",fontSize:"xx-large"}}  onClick={onRequestClose}>
            &times;
          </Button>

                </div>
              

                </div>
               
                
                <div className="flex flex-col items-start justify-start mt-[38px] w-full">
                  {/* <Input
                        name="input"
                        placeholder=" Section Name"
                        className="capitalize font-roboto p-0  placeholder-white-900 text-base text-left w-full h-[50px] pl-4 "
                        wrapClassName="common-pointer border-b border-white-700_99 border-solid w-full bg-[#292e34]"
                        style={{color:"white"}}
                        onChange={(e) => {
                          form.handleChange("name", e);
                        }}
                        errors={form?.errors?.name}
                        value={form?.values?.name}
                      
                        size="md"
                        variant="fill"
                /> */}

                  {/* {/ Add more input fields as needed /} */}
                </div>
                <div className="flex sm:flex-col flex-row gap-6 items-start justify-start w-full sm:w-full">
                  <div className="flex flex-col gap-2.5 items-center justify-start w-[48%] sm:w-full">
                    <div className="flex flex-col items-start justify-start pb-[3px] pr-[3px] w-full">
                      <Text
                     className="text-base text-[#6a717f]"
                        size="txtPoppinsSemiBold16"
                      >
                        First name
                      </Text>
                    </div>
                    <Input
                      name="input"
                      placeholder=""
                      className="p-5 placeholder:bg-white-A700 w-full h-[48px] text-[white]"
                      wrapClassName="h-12 rounded-[10px] w-[98%]"
                      onChange={(e) => {
                        form.handleChange("firstName", e);
                      }}
                      errors={form?.errors?.firstName}
                      value={form?.values?.firstName}
                      shape="round"
                      color="gray_400"
                      variant="outline"
                    ></Input>
                  </div>
                  <div className="flex flex-col gap-2.5 items-center justify-start w-[48%] sm:w-full">
                    <div className="flex flex-col items-start justify-start pb-0.5 pr-0.5 w-full">
                      <Text
                        className="text-base text-[#6a717f]"
                        size="txtPoppinsSemiBold16"
                      >
                        Last name
                      </Text>
                    </div>
                    <Input
                      name="input_One"
                      placeholder=""
                      className="p-5 placeholder:bg-white-A700 w-full h-[48px] text-[white]"
                      wrapClassName="h-12 outline outline-[1px] outline-gray-400 rounded-[10px] w-[98%]"
                      onChange={(e) => {
                        form.handleChange("lastName", e);
                      }}
                      errors={form?.errors?.lastName}
                      value={form?.values?.lastName}
                      shape="round"
                      color="white_A700"
                      variant="outline"
                    ></Input>
                  </div>
                </div>




                <div className="flex mt-[25px] sm:flex-col flex-row gap-6 items-start justify-start w-full sm:w-full">
                  <div className="flex flex-col gap-2.5 items-center justify-start w-[48%] sm:w-full">
                    <div className="flex flex-col items-start justify-start pb-[3px] pr-[3px] w-full">
                      <Text
                      className="text-base text-[#6a717f]"
                        size="txtPoppinsSemiBold16"
                      >
                        Email
                      </Text>
                    </div>
                    <Input
                      name="input_Two"
                      placeholder=""
                      className="p-5 placeholder:bg-white-A700 w-full h-[48px] text-[white]"
                      wrapClassName="outline outline-[1px] outline-gray-400 rounded-[10px] w-full"
                      onChange={(e) => {
                        form.handleChange("email", e);
                      }}
                      errors={form?.errors?.email}
                      value={form?.values?.email}
                      shape="round"
                      color="white_A700"
                      variant="outline"
                    ></Input>
                  </div>
                  <div className="flex flex-col gap-2.5 items-center justify-start w-[48%] sm:w-full">
                    <div className="flex flex-col font-poppins items-start justify-start pb-0.5 pr-0.5 w-full">
                      <Text
                        className="text-base text-[#6a717f]"
                        size="txtPoppinsSemiBold16"
                      >
                        Password
                      </Text>
                    </div>
                  
                    <Input
                      name="input_Two"
                      type="password"
                      placeholder=""
                      className="p-5 placeholder:bg-white-A700 w-full h-[48px] text-[white]"
                      wrapClassName="outline outline-[1px] outline-gray-400 rounded-[10px] w-full"
                      onChange={(e) => {
                        form.handleChange("password", e);
                      }}
                      errors={form?.errors?.password}
                      value={form?.values?.password}
                      shape="round"
                      color="white_A700"
                      variant="outline"
                    ></Input>
                  </div>
                </div>
               

                <div className="flex  mt-[25px] flex-col gap-1 items-start justify-start w-full sm:w-full">
                  <div className="flex flex-col items-start justify-start pb-[3px] pr-[3px] w-full">
                    <Text
                      className="text-base text-[#6a717f]"
                      size="txtPoppinsSemiBold16"
                    >
                      Contact No.
                    </Text>
                  </div>
                  <Input
                    name="input_Four"
                    placeholder=""
                    type="tel"
                    className="p-5 placeholder:bg-white-A700 w-full h-[48px] text-[white]"
                    wrapClassName="outline outline-[1px] outline-gray-400 rounded-[10px] w-full"
                    onChange={(e) => {
                      form.handleChange("phone", e);
                    }}
                    errors={form?.errors?.phone}
                    value={form?.values?.phone}
                    shape="round"
                    color="white_A700"
                    variant="outline"
                  ></Input>
                </div>
                




                <div className="flex  mt-[25px] flex-col gap-1 items-start justify-start w-full sm:w-full">
                  <div className="flex flex-col items-start justify-start pb-[3px] pr-[3px] w-full">
                    <Text
                      className="text-base text-[#6a717f]"
                      size="txtPoppinsSemiBold16"
                    >
                      DOB
                    </Text>
                  </div>
                  <div className=" capitalize font-roboto p-0 text-base text-left w-full common-pointer bg-[#292e34] text-white-A700  outline-none focus:border-b-2 focus:border-[white] focus:ring-0 appearance-none border rounded-[10px]">
                  <DOB placeholder='DOB' onChange={handleDobChange}  utcOffset={new Date().getTimezoneOffset()} />
                  </div>
                 

                </div>




                <div className="flex  mt-[25px] flex-col gap-1 items-start justify-start w-full sm:w-full">
                  <div className="flex flex-col items-start justify-start pb-[3px] pr-[3px] w-full">
                    <Text
                      className="text-base text-[#6a717f]"
                      size="txtPoppinsSemiBold16"
                    >
                      Country *
                    </Text>
                  </div>
                  <div className="flex flex-col font-roboto items-center justify-start w-full">
                  <Select
            key={resetSelectKey} // Force re-mount when key changes
            id="country"
            name="country"
            className=" capitalize font-roboto p-0 text-base text-left w-full common-pointer bg-[#292e34] text-white-A700  outline-none focus:border-b-2 focus:border-[white] focus:ring-0 appearance-none"
            options={countryList}
            placeholder="Select Country..."
            isSearchable={true}
            onChange={handleCountryChange}
            value={countryList.find((option) => option.value === selectedCountry)}
            styles={selectStyle}
           ></Select>

                  </div>
                </div>




                <div className="flex  mt-[25px] flex-col gap-1 items-start justify-start w-full sm:w-full">
                  <div className="flex flex-col items-start justify-start pb-[3px] pr-[3px] w-full">
                    <Text
                      className="text-base text-[#6a717f]"
                      size="txtPoppinsSemiBold16"
                    >
                      State *
                    </Text>
                  </div>
                  <div className="flex flex-col font-roboto items-center justify-start w-full">
                  <Select
            key={resetSelectKey} // Force re-mount when key changes
            id="state"
            name="state"
            className=" capitalize font-roboto p-0 text-base text-left w-full common-pointer bg-[#292e34] text-white-A700  outline-none focus:border-b-2 focus:border-[white] focus:ring-0 appearance-none"
            options={stateList}
            placeholder="Select State..."
            isSearchable={true}
            onChange={handleStateChange}
            value={stateList.find((option) => option.value === selectedState)}
            styles={selectStyle}
           ></Select>

                  </div>
                </div>





                <div className="flex  mt-[25px] flex-col gap-1 items-start justify-start w-full sm:w-full">
                  <div className="flex flex-col items-start justify-start pb-[3px] pr-[3px] w-full">
                    <Text
                      className="text-base text-[#6a717f]"
                      size="txtPoppinsSemiBold16"
                    >
                      City *
                    </Text>
                  </div>
                  <div className="flex flex-col font-roboto items-center justify-start w-full">
                  <Select
            key={resetSelectKey} // Force re-mount when key changes
            id="city"
            name="city"
            className=" capitalize font-roboto p-0 text-base text-left w-full common-pointer bg-[#292e34] text-white-A700  outline-none focus:border-b-2 focus:border-[white] focus:ring-0 appearance-none"
            options={cityList}
            placeholder="Select City..."
            isSearchable={true}
            onChange={handleCityChange}
            value={cityList.find((option) => option.value === selectedCity)}
            styles={selectStyle}
           ></Select>


                  </div>
                </div>
            
                <div className="flex mt-[25px] flex-col items-center justify-start pb-2 w-full">
                  <div className="flex flex-col gap-1 items-start justify-start w-full sm:w-full">
                    <div className="flex flex-col items-start justify-start pb-0.5 pr-0.5 w-full ">
                      <Text
                       className="text-base text-[#6a717f]"
                        size="txtPoppinsSemiBold16"
                      >
                       Address
                      </Text>
                    </div>
                    <Input
                      name="input_Three"
                      placeholder=""
                      className="p-5 placeholder:bg-white-A700 w-full h-[48px] text-[white]"
                      wrapClassName="outline outline-[1px] outline-gray-400 rounded-[10px] w-full"
                      onChange={(e) => {
                        form.handleChange("address", e);
                      }}
                      errors={form?.errors?.address}
                      value={form?.values?.address}
                      shape="round"
                      color="white_A700"
                      variant="outline"
                    ></Input>
                  </div>
                </div>


                
                <div className="flex  mt-[25px] flex-col gap-1 items-start justify-start w-full sm:w-full">
                  <div className="flex flex-col items-start justify-start pb-[3px] pr-[3px] w-full">
                    <Text
                      className="text-base text-[#6a717f]"
                      size="txtPoppinsSemiBold16"
                    >
                      User Role *
                    </Text>
                  </div>
                  <div className="flex flex-col font-roboto items-center justify-start w-full">
                    <SelectBox
                      className="bg-[transparent] outline outline-[1px] outline-gray-400 pl-3.5 pr-0.5 py-0.5 rounded-[10px] text-base text-[white] text-left w-full"
                      placeholderClassName="text-[#6a717f]"
                      indicator={
                        <Img
                          className="h-11 mr-[0] w-7 text-[#6a717f]"
                          src="images/img_div.svg"
                          alt="div"
                        />
                      }
                      isMulti={false}
                      name="div"
                      options={userRoleList}
                      isSearchable={true}
                      placeholder="Select..."
                      onChange={handleUserRoleChange}

                    />
                  </div>
                </div>

                <div className="flex  mt-[25px] flex-col gap-1 items-start justify-start w-full sm:w-full">
                  <div className="flex flex-col items-start justify-start pb-[3px] pr-[3px] w-full">
                    <Text
                      className="text-base text-[#6a717f]"
                      size="txtPoppinsSemiBold16"
                    >
                      Zipcode
                    </Text>
                  </div>
                  <Input
                    name="input_Four"
                    placeholder=""
                    className="p-5 placeholder:bg-white-A700 w-full h-[48px] text-[white]"
                    wrapClassName="outline outline-[1px] outline-gray-400 rounded-[10px] w-full"
                    onChange={(e) => {
                      form.handleChange("zipcode", e);
                    }}
                    value={form?.values?.zipcode}
                    shape="round"
                    color="white_A700"
                    variant="outline"
                  ></Input>
                </div>
               
              
                
                

                <div className="flex flex-col items-start justify-start w-full mt-20">
                  <Button
                    className="common-pointer cursor-pointer font-bold leading-[normal] min-w-[459px] sm:min-w-full text-center text-xl w-full"
                    shape="round"
                    size="md"
                    variant="gradient"
                    color="blue_600_indigo_900"
                    onClick={() => {
                        form.handleSubmit(createanaccount);
                      }}
                  >
                   Create
                  </Button>
                </div>
              </div>
            </div>
          </div>


          </div>
        
     
      <ToastContainer />
        </Modal>
      )
}

export default CreateUser;



