
import { Button, Img, Input, Text,SelectBox } from "components";
import React,{useEffect,useState,useRef} from 'react';
import DateRangePicker from 'components/DateRangePicker';
import TimePicker from 'components/Timepicker';
import moment from 'moment';
import ImageComponent from 'components/ImageComponent.js';
import Select from "react-select";
import ImageUploader from 'components/ImageUploader'
import { postUserData,editProfile } from "service/api";
import * as yup from "yup";
import useForm from "hooks/useForm";
import {  ToastContainer,toast } from "react-toastify";
import ResetPassword from "pages/ResetPassword";
import defaultProfile from "../../assets/images/defaultProfile.png"
const Profile = () => {
  const [resetSelectKey, setResetSelectKey] = useState(0);
  const [resetSelect, setResetSelect] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [name, setName] = useState();
  const [phone, setPhone] = useState();
  const [email, setEmail] = useState();
  const [role, setRole] = useState();
  const imageUploaderRef = useRef();
 const datePickerRef =useRef();
 const [isPasswordModalOpen, setIsPasswordModalOpen] = useState(false);
 const [selectedImage, setSelectedImage] = useState(null);
const uid=localStorage.getItem('LoginId')



const openPasswordModal = () => {
   
  setIsPasswordModalOpen(true);
};

const closePasswordModal = () => {
  setIsPasswordModalOpen(false);
}; 

const handleImageSelect = (imageUrl) => {
  setSelectedImage(imageUrl);

};

 useEffect(() => {
    
 
   loadUser();
 
     
   }, []);
 
 
   const loadUser = async () => {
     const req = {
       data: {
         uid: uid,
       },
     };
     try {
       const res = await postUserData(req);
       setData(res.data.data);
       setName(res.data.data[0].name)
       setEmail(res.data.data[0].email)
       setPhone(res.data.data[0].phone)
       setRole(res.data.data[0].role.id)
       setSelectedImage(res.data.data[0].userinfo.image)
      //  setIsLoading(false)
     } catch (error) {
       console.error('Error fetching data:', error);
     }
   };


   const updateProfile = async () => {
    const req = {
      data: {
        uid: uid,
        name:name?name:data[0]?.name,
        email:email?email:data[0]?.email,
        phone:phone?phone:data[0]?.phone,
        image: selectedImage?selectedImage:defaultProfile,

      },
    };
    try {
      const res = await editProfile(req).then(()=>{
        toast.success("Profile is Updated Succesfully!");
      })
      
     
     //  setIsLoading(false)
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };






  return (
    <>
   
   <div className="flex flex-col font-roboto items-center justify-start mx-auto w-full">
    <div  className="backdrop-opacity-[0.5] bg-gray-900  flex flex-col items-center justify-end   w-full">
    <div   className="flex md:flex-col flex-row  items-start justify-between mx-auto md:px-5 w-full">
    <div  className="bg-blue_gray-900_01 flex flex-col font-poppins  justify-start sm:px-5 px-[26px] rounded shadow-bs1 m-10 w-full">
    <div className="flex flex-row justify-between  overflow-x-auto text-center  mt-[40px]">
     

     

        <div className="flex flex-col items-center justify-center w-[fit-content] sm:w-full">
                  <Text
                    className="md:text-3xl text-center sm:text-[28px] text-[32px] text-white-A700 w-auto"
                    size="txtPoppins"
                  >
                   Profile
                  </Text>
                </div>
                <Button
                              className="cursor-pointer font-inter font-semibold leading-[normal] min-w-[128px] rounded-lg text-center text-sm"
                              color="indigo_A400"
                              size="sm"
                             onClick={openPasswordModal}
                            >
                              Reset Password 
                            </Button>
    
        </div>


        
               <div className="flex flex-row items-center justify-between mt-[38px] w-full">

               <ImageUploader
          ref={imageUploaderRef} 
          imageUrl={selectedImage?selectedImage:defaultProfile}
          // Attach the ref to ImageUploader
         onChange={handleImageSelect}
        />
              <div className="flex flex-row justify-between mt-[38px] w-full ml-20 gap-5">
              <div className="flex flex-col items-start justify-start  w-full">
              <input
                  name="Name"
                  placeholder=" Name"
                  className="common-pointer border border-white-700_99 border-solid w-full bg-[#292e34] capitalize font-roboto p-0 placeholder-white-900 text-base text-left w-full h-[50px] pl-4 common-pointer border border-white border-solid w-full bg-[#292e34] "
                  
                  style={{ color: 'white' , borderColor:"white"}}
                  onChange={(e) => {
                    
                    setName(e.target.value);
                  }}
                  value={name}
                  size="md"
                  variant="fill"
                />
              </div>
             



 </div>
              
 </div>

 <div className="flex flex-col items-start justify-start mt-[38px] w-full">
 
              <input
                  name="Email"
                  placeholder="Enter Email."
                  className="common-pointer border border-white-700_99 border-solid w-full bg-[#292e34] capitalize font-roboto p-0 placeholder-white-900 text-base text-left w-full h-[50px] pl-4 common-pointer border border-white border-solid w-full bg-[#292e34] "
                  
                  style={{ color: 'white' , borderColor:"white"}}
                  onChange={(e) => {
                    
                    setEmail (e.target.value);
                  }}
                  value={email}
                  size="md"
                  variant="fill"
                />
          

                  {/* Add more input fields as needed */}
                </div>

 <div className="flex flex-col items-start justify-start mt-[38px] w-full">
 
              <input
                  name="Phone"
                  placeholder="Contact No."
                  className="common-pointer border border-white-700_99 border-solid w-full bg-[#292e34] capitalize font-roboto p-0 placeholder-white-900 text-base text-left w-full h-[50px] pl-4 common-pointer border border-white border-solid w-full bg-[#292e34] "
                  
                  style={{ color: 'white' , borderColor:"white"}}
                  onChange={(e) => {
                    
                    setPhone(e.target.value);
                  }}
                  value={phone}
                  size="md"
                  variant="fill"
                />
          

                  {/* Add more input fields as needed */}
                </div>


                <div className="flex flex-col items-start justify-start w-full mt-20 mb-[40px]">
                <Button
                    className="common-pointer cursor-pointer font-bold leading-[normal] min-w-[459px] sm:min-w-full text-center text-xl w-full"
                    shape="round"
                    size="md"
                    variant="gradient"
                    color="blue_600_indigo_900"
                    onClick={() => {
                      updateProfile()
                     
                    }}
                  >
                    Update
                  </Button>
                </div>
<ResetPassword isOpen={isPasswordModalOpen} onRequestClose={closePasswordModal} email={email}/>


        </div>
  
     
     
     
        <ToastContainer />
  </div>
     </div></div>
    </>
  );
};

export default Profile;
