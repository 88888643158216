import React from "react";
import PropTypes from "prop-types";
import { ErrorMessage } from "../../components/ErrorMessage";

const variants = {
  fill: {
    gray_200: "bg-gray-200 text-black-900_87",
    white_A700: "bg-white-A700",
  },
  outline: { gray_400: "outline outline-[1px] outline-gray-400" },
};
const shapes = { round: "rounded-[10px]" };
const sizes = {
  xs: "pb-[35px] pt-px px-px",
  sm: "p-0.5",
  md: "pb-[1px] pt-[1px] px-[1px]",
};

const Input = React.forwardRef(
  (
    {
      wrapClassName = "",
      className = "",
      name = "",
      placeholder = "",
      type = "text",
      children,
      errors = [],
      label = "",
      prefix,
      suffix,
      onChange,
      shape = "",
      size = "",
      variant = "",
      color = "",
      value, // New prop to handle default value
      ...restProps
    },
    ref,
  ) => {
    const handleChange = (e) => {
      if (onChange) onChange(e?.target?.value);
    };

    return (
      <>
         <div
          className={`${wrapClassName} 
              ${shapes[shape] || ""} 
              ${variants[variant]?.[color] || ""} 
              ${sizes[size] || ""}`}
        >
          {!!label && label}
          {!!prefix && prefix}
          <input
            ref={ref}
            className={`${className} bg-transparent border-0`}
            type={type}
            name={name}
            onChange={handleChange}
            placeholder={placeholder}
            value={value} // Set the value prop here
            {...restProps}
          />
          {!!suffix && suffix}
        </div>
        {!!errors && <ErrorMessage errors={errors} />}
      </>
    );
  },
);

Input.propTypes = {
  wrapClassName: PropTypes.string,
  className: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  shape: PropTypes.oneOf(["round"]),
  size: PropTypes.oneOf(["xs", "sm", "md"]),
  variant: PropTypes.oneOf(["fill", "outline"]),
  color: PropTypes.oneOf(["gray_200", "white_A700", "gray_400"]),
};

export { Input };
// import React, { useState } from "react";
// import PropTypes from "prop-types";
// import { ErrorMessage } from "../../components/ErrorMessage";

// const variants = {
//   fill: {
//     gray_200: "bg-gray-200 text-black-900_87",
//     white_A700: "bg-white-A700",
//   },
//   outline: { gray_400: "outline outline-[1px] outline-gray-400" },
// };
// const shapes = { round: "rounded-[10px]" };
// const sizes = {
//   xs: "pb-[35px] pt-px px-px",
//   sm: "p-0.5",
//   md: "pb-[1px] pt-[1px] px-[1px]",
// };

// const Input = React.forwardRef(
//   (
//     {
//       wrapClassName = "",
//       className = "",
//       name = "",
//       placeholder = "",
//       type = "text",
//       children,
//       errors = [],
//       label = "",
//       prefix,
//       suffix,
//       onChange,
//       shape = "",
//       size = "",
//       variant = "",
//       color = "",
//       value,
//       ...restProps
//     },
//     ref,
//   ) => {
//     const [inputValue, setInputValue] = useState(value);

//     const handleChange = (e) => {
//       const newValue = e.target.value;
//       setInputValue(newValue); // Update the local state
//       if (onChange) onChange(newValue); // Propagate the change
//     };

//     return (
//       <>
//         <div
//           className={`${wrapClassName} 
//               ${shapes[shape] || ""} 
//               ${variants[variant]?.[color] || ""} 
//               ${sizes[size] || ""}`}
//         >
//           {!!label && label}
//           {!!prefix && prefix}
//           <input
//             ref={ref}
//             className={`${className} bg-transparent border-0`}
//             type={type}
//             name={name}
//             onChange={handleChange}
//             placeholder={placeholder}
//             value={inputValue} // Use local state as the value
//             {...restProps}
//           />
//           {!!suffix && suffix}
//         </div>
//         {!!errors && <ErrorMessage errors={errors} />}
//       </>
//     );
//   }
// );

// Input.propTypes = {
//   wrapClassName: PropTypes.string,
//   className: PropTypes.string,
//   name: PropTypes.string,
//   placeholder: PropTypes.string,
//   type: PropTypes.string,
//   shape: PropTypes.oneOf(["round"]),
//   size: PropTypes.oneOf(["xs", "sm", "md"]),
//   variant: PropTypes.oneOf(["fill", "outline"]),
//   color: PropTypes.oneOf(["gray_200", "white_A700", "gray_400"]),
//   value: PropTypes.string, // Update prop type to match the data type of your input
//   onChange: PropTypes.func, // Make onChange prop required or optional as needed
// };

// export { Input };
