import React, { useState,useEffect } from 'react';

import "../Custom.css"
import 'react-datepicker/dist/react-datepicker.css';
import Modal from 'pages/Modal';
import EventModal from 'pages/EventModal';
import SingleEvent from 'pages/SingleEvent';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import moment from 'moment';
import Charts from 'pages/Charts';
import { Button, Img, Line, List, Text } from "components";
import {getEvent,getBookingList,getTotalCustomers,getTotalEvents,getTotalVenues,getTotalReservations } from 'service/api';
import BookingList from 'pages/BookingList';
import "../Custom.css"
import { css } from '@emotion/react';
import { ScaleLoader } from 'react-spinners';

import Warning from "../../assets/images/warning.png"
const override = css`
 
`;




const DashboardPage = () => {
  const [allowed,setAllowed]=useState(true)
  const newallowed=localStorage.getItem('Access')
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEventModalOpen,setIsEventModalOpen]=useState(false);
  const [isChartModalOpen,setIsChartModalOpen]=useState(false);
  const [isSingleEventModalOpen,setIsSingleEventModalOpen]=useState(false);
 
  const [loading, setLoading] = useState(false);
  const [venueId,setVenueId]=useState(null);
  const [eventList,setEventList]=useState([]);
  const [eventId,setEventId]=useState();
  const [eventCount,setEventCount]=useState([]);
  const [totalVenue,setTotalVenue]=useState();
  const [totalCustomer,setTotalCustomer]=useState();
  const [totalReservation,setTotalReservation]=useState();



  const [date,setDate]=useState([]);
  const [ticketList,setTicketList]=useState([]);
  const [chartData,setChartData]=useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isEventLoading, setIsEventLoading] = useState(true);
  const [isChartLoading, setIsChartLoading] = useState(true);
  const [customDate,setCustomDate]=useState()
  const savedVenueId=localStorage.getItem('Venue')
  const settings = {
    dots: true,
    slidesToShow: 2,
    slidesToScroll: 2,
    customPaging: (i) => (
      <div
        style={{
          width: '15px',
          height: '15px',
          backgroundColor: i === 0 ? 'red' : 'blue', // Customize the dot colors here
          borderRadius: '50%',
        }}
      />
    ),
  };
  useEffect(()=>{
    setAllowed(newallowed)
  })
  useEffect(() => {
    // const savedVenueId = Cookies.get('venueId');
    
    const savedVenueId=localStorage.getItem('Venue')
    
    setVenueId(savedVenueId);
     
      fetchData(savedVenueId);
      fetchDashboardCards(savedVenueId)
    
  }, [savedVenueId]);

const fetchData = async (venueId) => {
    
    const req = { 
      data:{venue_id:venueId }};

    try {
      const res = await getEvent(req);

      
      setEventList(res.data.data);
     
      setIsEventLoading(false);
       
      

      // setTimeout(() => {
      //   const dummyData = ['Item 1', 'Item 2', 'Item 3'];
      //   setData(dummyData);
      //   setIsLoading(false);
      // }, 2000);
    }
    catch {
      console.error("there is an error");
    };
  
   
  };



  const fetchDashboardCards = async (venueId) => {
  
    const req = { 
      data:{venue_id:venueId }};

    try {
      const events = await getTotalEvents(req);
      setEventCount(events.data.count);
     
      const venues = await getTotalVenues(req);
      setTotalVenue(venues.data.totalVenuesCount);
     
      const customers = await getTotalCustomers(req);
      setTotalCustomer(customers.data.count);

      const Reservations = await getTotalReservations(req);
      setTotalReservation(Reservations.data.count);
     
    
      

      setIsChartLoading(false);
    }
    catch {
      console.error("there is an error");
    };
  
   
  };


 

  // const openChartModal = () => {
  //   setIsChartModalOpen(true);
  // };

  // const closeChartModal = () => {
  //   setIsChartModalOpen(false);
  // };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  const openEventModal = () => {
     setIsEventModalOpen(true);
  };

  const closeEventModal = () => {
     setIsEventModalOpen(false);
  };

  const openIndividualEventModal = (id) => {
   setEventId(id);
    setIsSingleEventModalOpen(true);
  };

  const closeIndividualEventModal = () => {
    setIsSingleEventModalOpen(false);
  };

  useEffect(() => {
    const today = new Date();
    const options = {
      weekday: 'long',
      month: 'long',
      day: 'numeric',
      year: 'numeric',
    };

    // Format the date
    const formattedDate = today.toLocaleDateString('en-US', options);

    // Add the appropriate suffix to the day
    const daySuffix = (day) => {
      if (day >= 11 && day <= 13) {
        return 'th';
      }
      switch (day % 10) {
        case 1:
          return 'st';
        case 2:
          return 'nd';
        case 3:
          return 'rd';
        default:
          return 'th';
      }
    };

    const day = today.getDate();
    const dayWithSuffix = day ;

    // Replace the day in the formatted date
    const finalFormattedDate = formattedDate.replace(
      new RegExp(today.getDate(), 'g'),
      dayWithSuffix
    );

    setDate(finalFormattedDate);
  }, []);

  const columns = [
    // Define your columns here
    { Header: 'EVENT', accessor: 'event_name' },
    {
    Header: 'NAME',
    accessor: 'first_name',
    Cell: ({ row }) => {
      // Combine first_name and last_name
      const fullName = `${row.original.first_name} ${row.original.last_name}`;
      return <span>{fullName}</span>;
    },
  },
    { Header: 'EMAIL', accessor: 'email'  },
    { Header: 'CONTACT NO', accessor: 'phone' },
    { Header: 'SECTION', accessor: 'section' },
    { Header: 'GUEST COUNT', accessor: 'no_of_seats' },
    { Header: 'ARRIVAL TIME', accessor: 'arrival_time' },
    { Header: 'BOOKING NOTES', accessor: 'booking_note' },
    // Add more columns as needed
  ];

  // const cardStyles = {
  //   boxShadow: '0 0 20px rgba(255, 105, 180, 0.8)', // Bright pink shadow
  //   borderRadius: '8px',
  //   padding: '16px',
  //   backgroundColor:'transparent'
  //   // Set the desired background color
  //   // Other styling properties...
  // };
  

  useEffect(()=>{
    if(savedVenueId!="null"){
      loadBooking();
     
    }
    
  },[savedVenueId])

  const loadBooking = async()=>{

    const req={
      data:{
        venue_id:savedVenueId
      }
    }
    try {
      const res = await getBookingList(req);
      
      setTicketList(res.data.data)
      setIsLoading(false)
      
    }
    catch {
      console.error("there is an error");
    };
      
  }

 


 

  
  const dashboardStyles = {
    backgroundImage: 'url("https://images.unsplash.com/photo-1556035511-3168381ea4d4?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8bmlnaHQlMjBjbHVifGVufDB8fDB8fHww")', // Replace with your image URL
    backgroundSize: 'cover',
    position: 'relative',
  };

  const backdropStyles = {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundImage: 'url("https://images.unsplash.com/photo-1556035511-3168381ea4d4?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8bmlnaHQlMjBjbHVifGVufDB8fDB8fHww")', // Replace with your image URL
    backgroundSize: 'cover',
    filter: 'blur(10px)', // Adjust the blur intensity
    zIndex: -1,
  };
  const activeMenuStyles = {
    background: '-webkit-linear-gradient(left, #ff6eff, #c70039)', // Gradient from pink to dark pink
    // Other styling properties...
  };

 


  const dateFormatOptions = { year: 'numeric', month: 'short', day: 'numeric' };

  return (
    
    <>
    <div>
      {newallowed == 'true'?
      <>
    < div className={`relative ${isModalOpen || isEventModalOpen ? 'filter blur' : ''}`}>
    <div style={backdropStyles}></div>
      <div className="flex flex-col font-roboto items-center justify-start mx-auto w-full">
        <div className="backdrop-opacity-[0.5] bg-gray-900  flex flex-col items-center justify-end   w-full">
          <div className="flex md:flex-col flex-row  items-start justify-between mx-auto md:px-5 w-full">
          <div style={{display:"flex"}} className="w-full">
            
            <div className="flex flex-1 flex-col gap-[40px] items-center justify-start mb-[34px] w-full">
              <header className="bg-gray-900_01 flex md:flex-col flex-row md:gap-5 items-center  w-full justify-between">
          
                
              
              </header>
             

  <div className="flex flex-col font-inter items-center justify-start w-full md:w-full h-full  p-[2.1rem] ">
    <div className="flex flex-col gap-[26px] items-center justify-start w-full">
       <div className="flex md:flex-col flex-row gap-[22px] items-end justify-between w-full">
         <div className="flex md:flex-1 flex-col gap-[21px] items-center justify-start w-full md:w-full">
           <div className="flex flex-col gap-[34px] items-start justify-start w-full">
             <div className="w-full flex justify-between">
               <div>
                  <Text
                     className="text-4xl sm:text-[32px] md:text-[34px] text-white-A700"
                     size="txtInterSemiBold36"
                   >
                     {date}
                          </Text>
                          </div>
                          {/* <div>
                          <Button
                              className="cursor-pointer font-inter font-semibold leading-[normal] min-w-[128px] rounded-lg text-center text-sm "
                              color="indigo_A400"
                              size="sm"
                              onClick={openModal}
                            >
                              + Add Venue
                            </Button>
                            </div> */}
                        </div>
                        <div className="flex md:flex-col flex-row font-poppins gap-6 items-center justify-between ml-0.5 md:ml-[0] rounded shadow-bs w-full">
                      
                        {isChartLoading ? (
        <div style={{ display: 'flex', flexDirection:"column", justifyContent: 'center', alignItems: 'center', height: 'auto', width:"100%"}}>
          <ScaleLoader css={override} color={'#5051f9'} loading={isEventLoading} />
          
          <h1 style={{color:'#5051f9', fontSize:"20px"}}> Loading!</h1>
        </div>
      ) :
                      
                          <List
                           className="md:flex-1 sm:flex-col flex-row gap-6 grid sm:grid-cols-1 md:grid-cols-2 grid-cols-4 w-full md:w-full"
                            orientation="horizontal"
                          >
                            <div  className="bg-blue_gray-900_01 flex flex-col items-start justify-end sm:ml-[0] p-[7px] rounded w-full">
                              <div className="flex flex-col gap-6 items-start justify-start ml-3 md:ml-[0] mt-3 w-1/2 md:w-full">
                                <div className="flex flex-row gap-4 items-start justify-between w-full">
                                  <Img
                                    className="h-8 rounded w-8"
                                    src="images/img_productrequests.svg"
                                    alt="productrequests"
                                  />
                                  <div className="flex flex-col items-start justify-start">
                                    <Text
                                      className="text-[10px] text-white-A700 tracking-[0.10px]"
                                      size="txtPoppinsLight10"
                                    >
                                      Venue Total
                                    </Text>
                                    <Text
                                      className="text-sm text-white-A700 tracking-[0.14px]"
                                      size="txtPoppinsSemiBold14"
                                    >
                                      Customers
                                    </Text>
                                  </div>
                                </div>
                                <Text
                                  className="md:text-3xl sm:text-[28px] text-[32px] text-white-A700"
                                  size="txtPoppinsSemiBold32"
                                >
                                  {totalCustomer}
                                </Text>
                              </div>
                            </div>
                            <div  className="bg-blue_gray-900_01 flex flex-col items-start justify-end sm:ml-[0] p-[7px] rounded w-full">
                              <div className="flex flex-col gap-[25px] items-start justify-start ml-3 md:ml-[0] mt-3 w-[58%] md:w-full">
                                <div className="flex flex-row gap-5 items-start justify-between w-full">
                                  <div className="bg-indigo-A400 flex flex-col items-start justify-start p-1 rounded w-8">
                                    <div className="flex flex-col h-6 items-center justify-start w-6">
                                      <Img
                                        className="h-6 md:h-auto object-cover w-6"
                                        src="images/img_wine1.png"
                                        alt="wineOne"
                                      />
                                    </div>
                                  </div>
                                  <div className="flex flex-col items-start justify-start">
                                    <Text
                                      className="text-[10px] text-white-A700 tracking-[0.10px]"
                                      size="txtPoppinsLight10"
                                    >
                                      Venue
                                    </Text>
                                    <Text
                                      className="text-sm text-white-A700 tracking-[0.14px]"
                                      size="txtPoppinsSemiBold14"
                                    >
                                      Reservations
                                    </Text>
                                  </div>
                                </div>
                                <Text
                                  className="md:text-3xl sm:text-[28px] text-[32px] text-white-A700"
                                  size="txtPoppinsSemiBold32"
                                >
                                  {totalReservation}
                                </Text>
                              </div>
                            </div>
                            <div  className="bg-blue_gray-900_01 flex flex-col items-start justify-end sm:ml-[0] p-[7px] rounded w-full">
                              <div className="flex flex-col gap-[25px] items-start justify-start ml-3 md:ml-[0] mt-3 w-[54%] md:w-full">
                                <div className="flex flex-row gap-5 items-start justify-between w-full">
                                  <div className="bg-indigo-A400 flex flex-col h-8 items-center justify-start p-[5px] rounded w-8">
                                    <div className="flex flex-col h-[22px] items-center justify-start w-[22px]">
                                      <Img
                                        className="h-[22px] md:h-auto object-cover w-[22px]"
                                        src="images/img_ticket1.png"
                                        alt="ticketOne"
                                      />
                                    </div>
                                  </div>
                                  <div className="flex flex-col items-start justify-start">
                                    <Text
                                      className="text-[10px] text-white-A700 tracking-[0.10px]"
                                      size="txtPoppinsLight10"
                                    >
                                      Total
                                    </Text>
                                    <Text
                                      className="text-sm text-white-A700 tracking-[0.14px]"
                                      size="txtPoppinsSemiBold14"
                                    >
                                      Event Count 
                                    </Text>
                                  </div>
                                </div>
                                <Text
                                  className="md:text-3xl sm:text-[28px] text-[32px] text-white-A700"
                                  size="txtPoppinsSemiBold32"
                                >
                                {eventCount}
                                </Text>
                              </div>
                            </div>
                            <div   className="bg-blue_gray-900_01 flex flex-col items-start justify-end sm:ml-[0] p-[7px] rounded w-full">
                              <div className="flex flex-col gap-[25px] items-start justify-start md:ml-[0] ml-[11px] mt-[11px] w-[47%] md:w-full">
                                <div className="flex flex-row gap-4 items-end justify-start w-full">
                                  <div className="bg-indigo-A400 flex flex-col h-8 items-center justify-start p-[5px] rounded w-8">
                                    <div className="flex flex-col h-[22px] items-center justify-start w-[22px]">
                                    <Img
                                      className="h-[22px]"
                                      src="images/img_emergencyservice.png"
                                      alt="emergencyservic"
                                    />
                                    </div>
                                  </div>
                                  <div className="flex flex-col items-start justify-start">
                                    <Text
                                      className="text-[10px] text-white-A700 tracking-[0.10px]"
                                      size="txtPoppinsLight10"
                                    >
                                      Total
                                    </Text>
                                    <Text
                                      className="text-sm text-white-A700 tracking-[0.14px]"
                                      size="txtPoppinsSemiBold14"
                                    >
                                      Venue Count 
                                    </Text>
                                  </div>
                                </div>
                                <Text
                                  className="md:text-3xl sm:text-[28px] text-[32px] text-white-A700"
                                  size="txtPoppinsSemiBold32"
                                >
                                  {totalVenue}
                                </Text>
                              </div>
                              </div>
                            </List>}
                        </div>







                      </div>
                      <div   className="bg-blue_gray-900_01 flex flex-col font-poppins  justify-start sm:px-5 px-[26px] rounded shadow-bs1 w-full">
                        <div className="flex flex-col gap-10  justify-start py-9 w-full">
                        <div className="flex flex-row md:gap-10 justify-start w-auto md:w-full">
                            <div className="w-full flex justify-between">
                              <Text
                                className="text-[22px] sm:text-lg text-white-A700 md:text-xl"
                                size="txtPoppinsSemiBold22"
                              >
                                Events
                              </Text>
                            </div>
                            <Button
                              className="cursor-pointer font-inter font-semibold leading-[normal] min-w-[128px] rounded-lg text-center text-sm"
                              color="indigo_A400"
                              size="sm"
                              onClick={openEventModal}
                            >
                              + Add Event
                            </Button>
                          </div>
                          <div className="flex md:flex-col flex-row md:gap-10 items-center justify-between mb-[33px] w-full min-h-[170px]">
                          {isEventLoading ? (
        <div style={{ display: 'flex', flexDirection:"column", justifyContent: 'center', alignItems: 'center', height: 'auto', width:"100%"}}>
          <ScaleLoader css={override} color={'#5051f9'} loading={isEventLoading} />
          
          <h1 style={{color:'#5051f9', fontSize:"20px"}}> Loading!</h1>
        </div>
      ) : ( eventList.length!==0?(

                         <List
                           className="flex-1 sm:flex-col flex-row gap-[31px] grid md:grid-cols-1 grid-cols-1 w-full"
                           orientation="horizontal"
                         >
                          <Slider
  className="flex-1 sm:flex-col flex-row w-full"
  dots={true} // Add this if you want navigation dots
  infinite={false}
  slidesToShow={2} // Show one slide at a time
  slidesToScroll={2}
  centerMode={false} // Set to true if you want the slides to be centered
  centerPadding="200px" // Scroll two slides at a time
>
  {eventList.map((item) => (
    <div
      key={item.id}
      onClick={() => openIndividualEventModal(item.id)}
      style={{ marginRight: '10px' }}
      className="
      slider_list_block bg-black-900_11 border border-blue_gray-800 border-solid hover:cursor-pointer flex  sm:flex-col flex-row gap-[21px] items-center justify-start sm:ml-[0] hover:mx-0 p-2.5 hover:shadow-bs shadow-bs hover:w-full "
    >
      <Img
        className="sm:flex-1 h-[151px] md:h-auto object-cover w-[21%] sm:w-full"
        src={item.featured_image}
        alt="rectangleSixtyThree"
      />
      <div className="flex flex-col items-start justify-start">
        <Text
          className="sm:text-[19px] md:text-[21px] text-[23px] text-white-A700"
          size="txtPoppinsSemiBold23"
        >
          {item.name}
        </Text>
        <Text
          className="text-lg text-white-A700"
          size="txtPoppinsMedium18"
        >
          {moment(item.date_from).format('ddd, MMM D, YYYY')}
        
        </Text>
        <Text
          className="text-lg text-white-A700"
          size="txtPoppinsMedium18"
        >
          {item.time_from}-{item.time_to}
        </Text>
        <Text
          className="mt-3.5 text-sm text-white-A700"
          size="txtPoppinsRegular14"
        >
          {item.event_desc}
        </Text>
      </div>
    </div>
  ))}
</Slider>
                         </List>
      ):
      <div style={{ display: 'flex', flexDirection:"column", justifyContent: 'center', alignItems: 'center', height: 'auto', width:"100%"}}>
     
      <h1 className='messages'>No Event Yet , Add New!</h1>
    </div>)
                          }
                  </div>
                                         </div>
                                       </div>
                                     </div>
                    {/* <div className=" cursor-pointer flex md:flex-1 flex-col font-poppins gap-[18px] items-center justify-start md:mt-0 mt-[78px] w-[24%] md:w-full">
                      <div  className="bg-blue_gray-900_01 flex flex-col items-start justify-center p-4 rounded shadow-bs3 w-full">
                        <Text
                          className="text-[22px] sm:text-lg text-white-A700 md:text-xl"
                          size="txtPoppinsSemiBold22"
                        >
                          Clicker Charts
                        </Text>
                        <Text
                          className="mt-[3px] text-sm text-white-A700"
                          size="txtInterRegular14"
                        >
                          Last Event
                        </Text>
                        <div
                          className="bg-cover bg-no-repeat flex flex-col h-[197px] items-center justify-start mt-[15px] pt-[22px] w-full"
                          style={{
                            backgroundImage: "url('images/img_group50.svg')",
                          }}
                        >
                          <Img
                            className="h-[175px]"
                            src="images/img_chart.svg"
                            alt="chart"
                            onClick={() => {
                              openChartModal()
                               }} 
                          />
                        </div>
                        
                      </div>

                      <div className="bg-blue_gray-900_01 flex flex-col items-center justify-start p-3.5 rounded shadow-bs3 w-full">
                        <div className="flex flex-row items-start justify-between mb-24 w-[99%] md:w-full">
                          <Text
                            className="text-[15px] text-white-A700"
                            size="txtPoppinsSemiBold15"
                          >
                            Notes
                          </Text>
                          <Text
                            className="text-pink-300 text-xs"
                            size="txtPoppinsSemiBold12"
                          >
                            + Add Notes
                          </Text>
                        </div>
                      </div>
                    </div> */}
                  </div>

                    {/*  */}

                  <div  className="flex md:flex-col flex-row gap-[22px] items-end justify-between w-full">
                    <div className="flex md:flex-1 flex-col gap-[21px] items-center justify-start w-full md:w-full">

                      <div className="bg-blue_gray-900_01 flex flex-col font-poppins  justify-start sm:px-5 px-[26px] rounded shadow-bs1 w-full">
                        <div className="flex flex-col gap-10  justify-start py-9 w-full">
                        
                      
                          <div className="flex md:flex-col flex-row  items-center justify-between w-full min-h-[170px] ">
                      
                          {isLoading ? (
        <div style={{ display: 'flex', flexDirection:"column", justifyContent: 'center', alignItems: 'center', height: 'auto', width:"100%"}}>
          <ScaleLoader css={override} color={'#5051f9'} loading={isLoading} />
          <h1 style={{color:'#5051f9', fontSize:"20px"}}> Loading!</h1>
        </div>
      ) : (ticketList.length!==0?(
                          <BookingList columns={columns} data={ticketList} />
                          ):
                          <div style={{ display: 'flex', flexDirection:"column", justifyContent: 'center', alignItems: 'center', height: 'auto', width:"100%"}}>
                         
                          <h1 className='messages'> No Booking Available For This Venue!</h1>
                        </div>)
                        }
 </div>
                        </div>
                      </div>
                    </div>
                  
                  </div>

{/*  */}


                  <List 
                    className="sm:flex-col flex-row font-poppins  justify-center w-full"
                    orientation="horizontal"
                  >
                    <div className="bg-blue_gray-900_01 flex flex-1 flex-col items-center justify-end p-[22px] sm:px-5 rounded shadow-bs4 w-full">
                   <Charts></Charts>
                    </div>
                 
                  </List>
                </div>
              </div>





              








            </div>
            </div>







            
          </div>
        </div>
      </div>
      </div>
    
    <Modal isOpen={isModalOpen} onClose={closeModal} />
    <EventModal isEventOpen={isEventModalOpen} onEventClose={closeEventModal} />
  
    <SingleEvent isOpen={isSingleEventModalOpen}  onRequestClose={closeIndividualEventModal} eventId={eventId}/>
    </>:
    
    
    <>
    < div className={`relative ${isModalOpen || isEventModalOpen ? 'filter blur' : ''}`}>
    <div style={backdropStyles}></div>
      <div className="flex flex-col font-roboto items-center justify-start mx-auto w-full h-[93.5vh]">
        <div className="backdrop-opacity-[0.5] bg-gray-900  flex flex-col items-center justify-end   w-full">
          <div className="flex md:flex-col flex-row  items-start justify-between mx-auto md:px-5 w-full">
          <div style={{display:"flex"}} className="w-full">
            
            <div className="flex flex-1 flex-col gap-[40px] items-center justify-start mb-[34px] w-full">
              <header className="bg-gray-900_01 flex md:flex-col flex-row md:gap-5 items-center  w-full justify-between">
          
                
              
              </header>
             

              <div className="flex flex-col font-poppins items-center justify-start mx-auto w-full ">
           
           <div className="flex flex-col font-poppins items-center justify-start mx-auto w-full ">
             <div className="bg-no-repeat flex flex-col items-center justify-start p-10 md:p-5 w-full">
               <div className="bg-[#292e34] flex flex-col items-start justify-start max-w-[716px] p-[3.5rem] rounded-[24px] w-full ">
                 <div className='text-center w-full flex justify-between items-center'>
                 <div className="flex items-center justify-center w-full sm:w-full relative">
  {/* Delete icon */}
<img src={Warning} alt=" "
 /></div> 



              
              

                </div>
               
                
                
               
                <div className="flex flex-col items-center justify-center mt-[10px] w-full">
                
                <Text
                    className="md:text-3xl sm:text-[28px] text-[30px] text-white-A700 w-auto"
                    size="txtPoppins"
                  >
                  <h1>Subscription Expired</h1>
                  </Text>

                  <Text
                    className="md:text-3xl sm:text-[28px] text-[20px] text-white-A700 w-auto"
                    size="txtPoppins"
                  >
                  Please renew your subscription to continue .
                  </Text>
                </div>
              
                
                

                <div className="flex flex-row items-start justify-around w-full mt-10">
                  <Button
                    className="common-pointer cursor-pointer  leading-[normal]  text-center text-s w-[200px]"
                    shape="round"
                    size="md"
                    variant="gradient"
                    color="blue_600_indigo_900"
                    onClick={() => {
                     window.location.href="https://venuedrive.com/"
                    }}
                  >
                    Renew
                  </Button>
     


                </div>
              </div>
            </div>
          </div>


          </div>
    




              








            </div>
            </div>







            
          </div>
        </div>
      </div>
      </div>
    
   
    </>
    
    
    
    
    
    }
    </div>
    </>
  );
 
};

export default DashboardPage;