import React,{useEffect,useState} from 'react';
import * as yup from "yup";
import { Button, Img, Input, Text,SelectBox } from "components";
import useForm from 'hooks/useForm';
import {updateEvent,getEventType,getSingleEvent } from "service/api";
import {  ToastContainer,toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

import TimePicker from 'components/Timepicker';
import moment from 'moment';
import ImageComponent from 'components/ImageComponent.js';
import Modal from 'react-modal';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import ImageUploader from 'components/ImageUploader'
import { css } from '@emotion/react';
import { ScaleLoader } from 'react-spinners';

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;


function EditEvent({ isOpen, onRequestClose, eventId }) {
  const [name, setName] = useState('');
  const [organiser, setOrganiser] = useState('');
  const [description, setDescription ] = useState('');
  const [url, setUrl] = useState('');
  const [status, setStatus] = useState('');
  // const [organiser, setOrganiser] = useState('');
  const [hoveredButtonIndex, setHoveredButtonIndex] = useState(null);
  const [tableList, setTableList] = useState([]);


  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [formattedStartTime, setFormattedStartTime] = useState(null);
  const [formattedEndTime, setFormattedEndTime] = useState(null);
  const [selectedEventTypeValue, setSelectedEventTypeValue] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [eventTypeList, setEventTypeList] = useState([]);
 const [selectedEventType, setSelectedEventType] = useState(null);
 const vid= localStorage.getItem("Venue");
 const [eventDay, setEventDay] = useState(null);
 const [type, setType] = useState(null);
 const [response, setResponse] = useState([]);
 const[featuredImage,setFeaturedImage]=useState(null)
  const[flag,setFlag]=useState(null)
  const [isLoading, setIsLoading] = useState(true);


 const handleImageSelect = (imageUrl) => {
  setSelectedImage(imageUrl);

};
const handleStartChange = (start) => {
  setStartDate(start);

};
const handleEndChange = (end) => {
  setEndDate(end);

};

const handleTimeChange = (start) => {
  const formattedTime = moment(start).format('hh:mm A');
  setStartTime(start);
  setFormattedStartTime(formattedTime)
  
};
const handleTimeToChange = (end) => {
  const formattedTime = moment(end).format('hh:mm A');
  setEndTime(end);
  setFormattedEndTime(formattedTime)
};

const handleEventTypeChange = (selectedOption) => {

  if (selectedOption) {
    // Check if the selected option contains only the value
    if (selectedOption.label === undefined) {
      // Search for the corresponding option in eventTypeList
      const updatedEventType = eventTypeList.find(
        (option) => option.value === selectedOption.value
      );

      // Update the state with the found option
      setSelectedEventType((prev) => updatedEventType || prev);
      setSelectedEventTypeValue(selectedOption.value);
    } else {
      // If the selected option has both label and value, update the state
      setSelectedEventType(selectedOption);
      setSelectedEventTypeValue(selectedOption.value);
    }
  } else {
    // If selected option is null, reset the state
    setSelectedEventType(null);
    setSelectedEventTypeValue(null);
  }
};







const cid= localStorage.getItem("LoginId");




  useEffect(() => {
  
  if(eventId!="null" && eventTypeList!="null"){
    loadEvent();
  }
   
  },[eventId, eventTypeList]);
  
  // Remove the second useEffect block that handles selectedEventType
  async function loadEvent() {
    setIsLoading(true)
    try {
      const res = await ({ data: { id: eventId } });

      if (res.data) {
        setIsLoading(false);
        
        setResponse(res.data);
        setName(res.data.name);
        setOrganiser(res.data.event_organiser);
        setDescription(res.data.event_desc);
        setUrl(res.data.facebook_event_url);
        setStatus(res.data.event_status);

        const startDateObject = new Date(res.data.date_from);
        const endDateObject = new Date(res.data.date_to);
        const startTimeMoment = moment(res.data.time_from, 'hh:mm A');
        const endTimeMoment = moment(res.data.time_to, 'hh:mm A');

        setStartDate(startDateObject);
        setEndDate(endDateObject);
        setStartTime(startTimeMoment.toDate());
        setEndTime(endTimeMoment.toDate());
        setFormattedStartTime(res.data.time_from);
        setFormattedEndTime(res.data.time_to);
        setType(res.data.eventType);

        // Find the option with the matching event_type ID
        const selectedEventTypeOption = eventTypeList.find(
          (option) => option.value === res.data.event_type
        );

        // Set the selected option
        setSelectedEventType(selectedEventTypeOption);
        setSelectedEventTypeValue(selectedEventTypeOption ? selectedEventTypeOption.value : null);
        setFeaturedImage(res.data.featured_image)
        return res.data;
      }
    } catch (err) {
      console.error(err);
    }
  }
  
  

  async function handleEdit() {
    // Check if both name and price are defined
    // if (name === undefined || price === undefined) {
     
    //   return;
    // }

    const req = {
      data: {
        id: eventId,
        venue_id:vid,
        name:name,
        featured_image:featuredImage,
        date_from:startDate,
        date_to:endDate,
        time_from:formattedStartTime,
        time_to:formattedEndTime,
        event_type:selectedEventTypeValue,
        event_desc:description,
        facebook_event_url: url,
        event_status:status,
        event_organiser:organiser,




      },
    };

    try {
      const res = await updateEvent(req);

      toast.success('Event is updated Successfully!');
      setTimeout(() => {
        onRequestClose();
        // setName("");
        // setPrice("");
        // window.location.href = '/reservation';
      }, 3000);
    } catch (err) {
      console.error(err);
      toast.error('Something Went Wrong!');
    }
  }

           /////////Event_Type/////////
      


// const handleEventTypeChange = (selectedOption) => {

//   setSelectedEventType(selectedOption);
 
// };
useEffect(()=>{
  eventType();
},[])

async function eventType() {
  const req = {};

  await getEventType(req)
    .then((res) => {
      

      let options;

      if (res.data.data.length === 1) {
        
        options = [
          {
            label: res.data.data[0].name,
            value: res.data.data[0].id,
          },
        ];
      } else {
       
        options = res.data.data.map((item) => ({
          label: item.name,
          value: item.id,
        }));
      }

      setEventTypeList(options);
      
    })
    .catch((err) => {
      console.error(err);
    });
}



  return (
    <Modal
    isOpen={isOpen}
    onRequestClose={onRequestClose}
    contentLabel="Example Modal"
    style={{
      overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
      },
      content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        background: 'none',
        border: 'none',
        padding: 0,
        overflow: 'auto',
        maxHeight: '100vh', // Set a max height for the content
      },
    }}
  >
     
      <div className="flex flex-col font-poppins items-center justify-start mx-auto w-full ">
        <div className="flex flex-col font-poppins items-center justify-start mx-auto w-full ">
          <div className="bg-no-repeat flex flex-col items-center justify-start p-10 md:p-5 w-full">
          {isLoading ? (
        <div style={{ display: 'flex', flexDirection:"column", justifyContent: 'center', alignItems: 'center', height: 'auto', width:"100%"}}>
          <ScaleLoader css={override} color={'#5051f9'} loading={isLoading} />
          
          <h1 style={{color:'#5051f9', fontSize:"20px"}}> Loading!</h1>
        </div>
      ) :(
          <div className="bg-[#292e34] flex flex-col items-start justify-start max-w-[716px] p-[3.5rem] rounded-[24px] w-full ">
                <div className='text-center w-full flex justify-between items-center'>
                <div className="flex flex-col items-center justify-center w-[534px] sm:w-full">
                  <Text
                    className="md:text-3xl sm:text-[28px] text-[32px] text-white-A700 w-auto"
                    size="txtPoppins"
                  >
                   Edit Event
                  </Text>
                </div>
                <Button className="modal-close" style={{color:"white",fontSize:"xx-large"}}  onClick={onRequestClose}>
            &times;
          </Button>

                </div>
               
                
                <div className="flex flex-col items-start justify-start mt-[38px] w-full">
                
                 <input
                  name="Name"
                  placeholder=" Event Name"
                  className="common-pointer border-[transparent]  w-full bg-[#292e34]  font-roboto p-0 placeholder-white-900 text-base text-left w-full h-[50px] pl-4 common-pointer w-full bg-[#292e34] "
                  
                  style={{ color: 'white' ,borderBottom:"1px solid white " }}
                  onChange={(e) => {
                    

                    
                    setName(e.target.value);
                  }}
                  value={name}
                  size="md"
                  variant="fill"
                />

                  {/* Add more input fields as needed */}
                </div>
               <div className="flex flex-row items-center justify-between mt-[38px] w-full">

               <ImageUploader imageUrl={featuredImage} onChange={handleImageSelect} />

              
               <div >


 <div className="flex flex-row items-start justify-start w-full
 border-b border-white-700_99 border-solid ">


<DatePicker
        selected={startDate}
        onChange={handleStartChange}
        startDate={startDate}
        dateFormat="yyyy-MM-dd"
        selectsStart
        placeholderText="Start Date"
        className="custom-datepicker"
      />

<DatePicker
        selected={endDate}
        onChange={handleEndChange}
        startDate={endDate}
        dateFormat="yyyy-MM-dd"
        selectsEnd
        placeholderText="End Date"
        className="custom-datepicker"
      />
 </div>
 
 <div className="flex flex-row justify-between mt-[38px] w-full border-b border-white-700_99 border-solid">
 <TimePicker 
          onChange={handleTimeChange}
          value={startTime}
          placeholder="Time From"
          className="custom-timepicker" 
        />
        <TimePicker 
          onChange={handleTimeToChange}
          value={endTime}
          placeholder="Time To"
          className="custom-timepicker" 
        />
 



 </div>
 </div>
 </div>

 

 
                
                
                <div className="flex flex-col items-start justify-start mt-[38px] w-full">
                  

<input
                   name="event_organiser"
                   placeholder="Event Organiser"
                  className="common-pointer border-[transparent]  w-full bg-[#292e34]  font-roboto p-0 placeholder-white-900 text-base text-left w-full h-[50px] pl-4 common-pointer w-full bg-[#292e34] "
                  
                  style={{ color: 'white' ,borderBottom:"1px solid white " }}
                  onChange={(e) => {
                   setOrganiser(e.target.value);
                  }}
                  value={organiser}
                  size="md"
                  variant="fill"
                />
                  {/* Add more input fields as needed */}
                </div>
                <div className="flex flex-col items-start justify-start mt-[38px] w-full">
                  
                  <input
                  name="event_desc"
                  placeholder="Description"
                  className="common-pointer border-[transparent]  w-full bg-[#292e34]  font-roboto p-0 placeholder-white-900 text-base text-left w-full h-[50px] pl-4 common-pointer w-full bg-[#292e34] "
                  
                  style={{ color: 'white' ,borderBottom:"1px solid white " }}
                  onChange={(e) => {
                    

                    
                    setDescription(e.target.value);
                  }}
                  value={description}
                  size="md"
                  variant="fill"
                />
                  {/* Add more input fields as needed */}
                </div>
                <div className="flex flex-col items-start justify-start mt-[38px] w-full">
                 
                    <input
                  name="facebook_event_url"
                  placeholder="Event URL"
                  className="common-pointer border-[transparent]  w-full bg-[#292e34]  font-roboto p-0 placeholder-white-900 text-base text-left w-full h-[50px] pl-4 common-pointer w-full bg-[#292e34] "
                  
                  style={{ color: 'white' ,borderBottom:"1px solid white " }}
                  onChange={(e) => {
                    

                    
                    setUrl(e.target.value);
                  }}
                  value={url}
                  size="md"
                  variant="fill"
                />
                  {/* Add more input fields as needed */}
                </div>
                <div className="flex flex-col items-start justify-start mt-[38px] w-full">
                
                    <input
                  name="event_status"
                  placeholder="Event Status"
                  className="common-pointer border-[transparent]  w-full bg-[#292e34]  font-roboto p-0 placeholder-white-900 text-base text-left w-full h-[50px] pl-4 common-pointer w-full bg-[#292e34] "
                  
                  style={{ color: 'white' ,borderBottom:"1px solid white " }}
                  onChange={(e) => {
                    

                    
                    setStatus(e.target.value);
                  }}
                  value={status}
                  size="md"
                  variant="fill"
                />
                  {/* Add more input fields as needed */}
                </div>
                <div className="flex flex-col items-start justify-start mt-[38px] w-full">
               
                  {/* <Input
                    name="featured_image"
                    placeholder="Featured Images"
                    className="capitalize font-roboto p-0 placeholder:text-white-900 text-base text-left w-full"
                    wrapClassName="common-pointer border-b border-white-700_99 border-solid w-full bg-[#292e34]"
                    
                    onChange={(e) => {
                      form.handleChange("timezone", e);
                    }}
                    errors={form?.errors?.timezone}
                    value={form?.values?.timezone}
                    style={{color:"white"}}
                    size="md"
                    variant="fill"
                  /> */}
                  {/* Add more input fields as needed */}
                </div>


                <div className="flex flex-col items-start justify-start w-full mt-20">
                <Button
                    className="common-pointer cursor-pointer font-bold leading-[normal] min-w-[459px] sm:min-w-full text-center text-xl w-full"
                    shape="round"
                    size="md"
                    variant="gradient"
                    color="blue_600_indigo_900"
                    onClick={
                      handleEdit
                    }
                  >
                    Update
                  </Button>
                </div>
              </div>)}
          </div>
        </div>
      </div>

      <ToastContainer
    
      style={{
       
        top: '50%',
        left: '50%',
        transform: 'translateX(-50%)',
        
        
      }}
    />
    </Modal>
  );
}

export default EditEvent;
