const stripe = require('stripe')('sk_test_51P4MLnSEFrgSUuJZ6ztZmxIpgQDHjmigeYckj798BKkwU2tg2Is1wBWJlumpX6jCg0F5UMvlopH0vWMDYd5eaSsK00Ybrgjvu8');
export default async (req, res) => {
    const session = await stripe.checkout.sessions.create({
      line_items: [
        {
          // Provide the exact Price ID (for example, pr_1234) of the product you want to sell
          price: '{{PRICE_ID}}',
          quantity: 1,
        },
      ],
      mode: 'payment',
      success_url: `http://localhost:3000?success=true`,
      cancel_url: `http://localhost:3000?canceled=true`,
    });
  
    res.redirect(303, session.url);
  };