import React,{useState,useEffect} from 'react'
import { Button, Input, Text,SelectBox ,Img} from "components";
import axios from 'axios';
import useForm from "hooks/useForm";
import * as yup from "yup";
import {  ToastContainer,toast } from "react-toastify";
import { postSettings ,getSettings} from 'service/api';
function Settingss() {
    const [selectedValues, setSelectedValues] = useState([]);
    const [storedData, setStoredData] = useState([]);
    const [modes,setModes]=useState([]);
    const [latestEntry,setLatestEntry]=useState([]);
    const [secretKey,setSecretKey]=useState("");
    const [publicKey,setPublicKey]=useState("");
    const [licKey,setLicKey]=useState("");
    const [paymentTypes,setPaymentTypes]=useState([]);
    const[settingId,setSettingId]=useState()
    const UserId=localStorage.getItem('LoginId');

    const vid= localStorage.getItem('Venue')
    const formValidationSchema = yup.object().shape({
                    //   public: yup.string().required("Public Key  is required"),
                    //   : yup.string().required("Price count is required"),
                      
                      });
                
            const form = useForm(
                        {
                          payment_types: "",
                          public:"",
                          secret:"",
                          license:"",
                        
                        },
                        {
                          validate: true,
                          validateSchema: formValidationSchema,
                          validationOnChange: true,
                        },
                      );




useEffect(()=>{
  if(vid){
    getSettingList()
  }
  
},[vid])

    useEffect(()=>{
        payment_mode()
        
    },[])
    useEffect(()=>{
      load()
    },[storedData])
    const payment_mode=async()=>{
     await axios.get("https://event-backend.isdemo.in/api/v1/paymentMode")
     .then((data)=>{
        setModes(data.data.data)
     })
    }
    // Step 3: Define an onChange event handler to update the array of selected values
    const handleCheckboxChange = (weekday) => {
      setSelectedValues((prevSelectedValues) => {
        // Check if prevSelectedValues is an array
        if (!Array.isArray(prevSelectedValues)) {
          prevSelectedValues = [];
        }
        // Check if the current weekday ID is already in the selectedValues array
        const isSelected = prevSelectedValues.includes(weekday.id);
        // If the checkbox is already checked, uncheck it; otherwise, check it
        if (isSelected) {
          // Filter out the current weekday ID to uncheck it
          return prevSelectedValues.filter((id) => id !== weekday.id);
        } else {
          // Add the current weekday ID to the selectedValues array to check it
          return [...prevSelectedValues, weekday.id];
        }
      });
    };
    
    
    
    
    
      
      

async function getSettingList(){
  const req={
    data:{
      venue_id:vid,
      user_id:UserId,
    }
  }
  await getSettings(req)
  .then((res) => {
    setStoredData(res.data.data)
    
    
    
  
  })
  .catch((err) => {
    console.error(err);
    toast.error("Something Went Wrong!");
  });
}
    async function addSettings(data) {
        
          const req = {
      
            data: {
                venue_id:vid,
              payment_types:selectedValues,
              secret_key: selectedValues.includes(2)?secretKey:null,
              public_key: selectedValues.includes(2)?publicKey:null,
              license_key:licKey,
              user_id:UserId,
              id:settingId,
            },
      
          };
       await   postSettings(req)
            .then((res) => {
              
              
          
              
              toast.success("Settings are added Succesfully!");
              setTimeout(() => {
                window.location.href="/"
              }, 3000);
            
            })
            .catch((err) => {
              console.error(err);
              toast.error("Something Went Wrong!");
            });
        }


        async function load(){
          const latestEntry = storedData.reduce((latest, current) => {
            if (!latest || new Date(current.created_at) > new Date(latest.created_at)) {
              return current;
            }
            return latest;
          }, null);
        
          setLatestEntry(latestEntry)
          const paymentTypesArray = latestEntry?.payment_type ? latestEntry.payment_type.split(',').map(Number) : [];
          setPaymentTypes(paymentTypesArray);
          setSecretKey(latestEntry?.stripe_secret_key);
          setPublicKey(latestEntry?.stripe_public_key);
          setLicKey(latestEntry?.license_key);
          setSettingId(latestEntry?.id)
          setSelectedValues(paymentTypesArray || []);
          // Now you have the latest entry, you can set your variables accordingly
          
        }
    return (

        <div className="flex flex-col font-roboto items-center justify-start mx-auto w-full">
        <div  className="backdrop-opacity-[0.5] bg-gray-900  flex flex-col items-center justify-end   w-full">
        <div   className="flex md:flex-col flex-row  items-start justify-between mx-auto md:px-5 w-full">
        
       
     <div  className="bg-blue_gray-900_01 flex flex-col font-poppins  text-center sm:px-5 px-[26px] rounded shadow-bs1 m-10 w-full text-[white]">
<div className='text-center text-[white] flex justify-around'>
     <div 
      className="bg-[#292e34] flex flex-col max-w-[716px] p-[3.5rem] rounded-[24px] w-full ">
                 <div className='text-center w-full flex justify-between items-center'>
                 
                <div className="flex flex-col items-center justify-center w-[534px] sm:w-full">
                  <Text
                    className="md:text-3xl sm:text-[28px] text-[32px] text-white-A700 w-auto"
                    size="txtPoppins"
                  >
                    Settings
                  </Text>
                </div>
          
              

                </div>
               
                
                <div className="flex flex-col items-start justify-start mt-[38px] w-full">
                <label className='text-[white]'>Payment Mode</label>
                <div className="flex flex-col items-start justify-start mt-[38px] w-full">
                <ul className='flex flex-row'>
                {modes?.map((weekday) => (
  <li key={weekday.name} className="items-center space-x-2 mr-6">
    <input
      type="checkbox"
      id={`checkbox-${weekday.id}`}
      className="rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
      checked={selectedValues.includes(weekday.id)}
      onChange={(e) => handleCheckboxChange(weekday)}
    />
    <label htmlFor={`checkbox-${weekday.name}`} className="text-gray-700">
      {weekday.name}
    </label>
  </li>
))}

      </ul>
               
                
                
                
                
                
                
              </div>
                 
                </div>
               
                {selectedValues.includes(2) ? 
                
                <div>
               <div className="flex flex-col items-start justify-start mt-[38px] w-full">
                <input
                  name="input"
                  placeholder=" Enter Stripe Secret Key"
                  className="capitalize border-b border-white-700_99  font-roboto p-0 placeholder:text-white-900 text-base text-left w-full bg-[#292e34] h-[50px] pl-4"
                  type="text"
                  onChange={(e) => {
                    setSecretKey(e.target.value)
                  }}
                  errors={form?.errors?.public}
                  value={secretKey}
                  style={{color:"white"}}
                 min="1"
                  size="md"
                  variant="fill"
                />
                {/* {/ Add more input fields as needed /} */}
              </div>
              <div className="flex flex-col items-start justify-start mt-[38px] w-full">
                <input
                  name="input"
                  placeholder=" Enter Stripe Public Key"
                  className="capitalize border-b border-white-700_99  font-roboto p-0 placeholder:text-white-900 text-base text-left w-full bg-[#292e34] h-[50px] pl-4"
                  
                  type="text"
                  onChange={(e) => {
                    setPublicKey(e.target.value)
                  }}
                  errors={form?.errors?.secret}
                  value={publicKey}
                  style={{color:"white"}}
                 min="1"
                  size="md"
                  variant="fill"
                />
                {/* {/ Add more input fields as needed /} */}
              </div>
            </div>

              
                
                
                
                : null}
              
              
                
              {/* <div className="flex flex-col items-start justify-start mt-[38px] w-full">
                <input
                  name="input"
                  placeholder=" Enter License Key"
                  className="capitalize border-b border-white-700_99  font-roboto p-0 placeholder:text-white-900 text-base text-left w-full bg-[#292e34] h-[50px] pl-4"
                  type="text"
                  onChange={(e) => {
                    setLicKey(e.target.value)
                  }}
                  errors={form?.errors?.public}
                  value={licKey}
                  style={{color:"white"}}
                 min="1"
                  size="md"
                  variant="fill"
                />
              
              </div> */}

                <div className="flex flex-col items-start justify-start w-full mt-20">
                  <Button
                    className="common-pointer cursor-pointer font-bold leading-[normal] min-w-[459px] sm:min-w-full text-center text-xl w-full"
                    shape="round"
                    size="md"
                    variant="gradient"
                    color="blue_600_indigo_900"
                    onClick={() => {
                      form.handleSubmit(addSettings);
                    }}
                  >
                    Add 
                  </Button>
                </div>
              </div>
</div>
            </div>
            </div>
          </div>
          <ToastContainer />
        </div>
        
      
        
        
        
        
        
        
    
   
      );
}

export default Settingss